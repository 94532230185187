<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
  >
    <g id="Group_3449" data-name="Group 3449" transform="translate(0 -0.423)">
      <circle
        id="Ellipse_27"
        data-name="Ellipse 27"
        cx="10.5"
        cy="10.5"
        r="10.5"
        transform="translate(0 0.423)"
        fill="rgba(37,157,198,0.1)"
      />
      <path
        id="Path_10402"
        data-name="Path 10402"
        d="M9,12.337l2.337,2.337L16.011,10"
        transform="translate(-2.005 -1.837)"
        fill="none"
        stroke="rgba(37,157,198,0.8)"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>
