<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="92.584"
    height="87"
    viewBox="0 0 92.584 87"
  >
    <g id="Group_3671" data-name="Group 3671" transform="translate(-164 -627)">
      <g id="Group_3654" data-name="Group 3654">
        <circle
          id="Ellipse_1"
          data-name="Ellipse 1"
          cx="43.5"
          cy="43.5"
          r="43.5"
          transform="translate(167 627)"
          fill="rgba(37,157,198,0.1)"
        />
        <g
          id="Group_3526"
          data-name="Group 3526"
          transform="translate(164 628)"
        >
          <g id="Group_3518" data-name="Group 3518" transform="translate(0 0)">
            <path
              id="Path_10443"
              data-name="Path 10443"
              d="M92.869,19.859C75.543,2.534,76.843,3.833,76.41,3.544a1.454,1.454,0,0,1-.433-.144H29.92a1.364,1.364,0,0,0-1.444,1.444V20.292c-1.155.866-2.166,1.733-3.176,2.6A27.1,27.1,0,0,0,24.289,60.14l-3.754,3.754L18.37,61.729a1.4,1.4,0,0,0-2.021,0L2.633,75.444a5.753,5.753,0,0,0,0,8.23l.289.289a5.753,5.753,0,0,0,8.23,0L24.867,70.247a1.4,1.4,0,0,0,0-2.021L22.7,65.915l3.754-3.754c.722.578,1.3,1.155,2.021,1.733V87.283a1.364,1.364,0,0,0,1.444,1.444H92a1.364,1.364,0,0,0,1.444-1.444V21.158A1.166,1.166,0,0,0,92.869,19.859ZM9.13,81.8a2.79,2.79,0,0,1-4.043,0L4.8,81.508a2.791,2.791,0,0,1,0-4.043L17.5,64.76l2.166,2.166h0l2.166,2.166ZM88.537,19.57H77.42V8.309ZM27.321,25.057a24.2,24.2,0,1,1,0,34.217A24.313,24.313,0,0,1,27.321,25.057ZM90.559,85.984H31.364V65.771a27.01,27.01,0,0,0,32.2-42.736,26.8,26.8,0,0,0-32.2-4.476V6.288H74.533V21.014a1.364,1.364,0,0,0,1.444,1.444H90.559Z"
              transform="translate(-0.9 -3.4)"
              fill="#259dc6"
            />
          </g>
          <g
            id="Group_3519"
            data-name="Group 3519"
            transform="translate(23.269 18.444)"
          >
            <path
              id="Path_10444"
              data-name="Path 10444"
              d="M21.035,48.7a25.1,25.1,0,0,0,1.877,2.166,20.3,20.3,0,0,0,28.731,0A25.1,25.1,0,0,0,53.52,48.7l.144-.144a20.283,20.283,0,1,0-32.629.144Zm3.9.144a8.253,8.253,0,0,1-1.011-1.155,16.007,16.007,0,0,1,26.565,0,7.231,7.231,0,0,1-1.011,1.155A17.341,17.341,0,0,1,24.933,48.84Zm6.353-17.181a6.064,6.064,0,1,1,6.064,6.064A6.1,6.1,0,0,1,31.286,31.659Zm-6.353-7.508a17.515,17.515,0,0,1,24.689,0,17.313,17.313,0,0,1,2.743,21.079,19.156,19.156,0,0,0-9.385-6.5,8.951,8.951,0,1,0-11.261,0,17.95,17.95,0,0,0-9.385,6.5A17.514,17.514,0,0,1,24.933,24.152Z"
              transform="translate(-17.017 -16.175)"
              fill="#31418f"
            />
          </g>
          <g
            id="Group_3520"
            data-name="Group 3520"
            transform="translate(35.806 72.911)"
          >
            <path
              id="Path_10445"
              data-name="Path 10445"
              d="M25.7,55.344a1.364,1.364,0,0,0,1.444,1.444H74.067a1.444,1.444,0,1,0,0-2.888H27.144A1.553,1.553,0,0,0,25.7,55.344Z"
              transform="translate(-25.7 -53.9)"
              fill="#31418f"
            />
          </g>
          <g
            id="Group_3521"
            data-name="Group 3521"
            transform="translate(77.386 35.95)"
          >
            <path
              id="Path_10446"
              data-name="Path 10446"
              d="M55.944,31.188h2.888a1.444,1.444,0,1,0,0-2.888H55.944a1.444,1.444,0,1,0,0,2.888Z"
              transform="translate(-54.5 -28.3)"
              fill="#f7c26f"
            />
          </g>
          <g
            id="Group_3522"
            data-name="Group 3522"
            transform="translate(77.386 26.71)"
          >
            <path
              id="Path_10447"
              data-name="Path 10447"
              d="M55.944,24.788h2.888a1.444,1.444,0,1,0,0-2.888H55.944A1.364,1.364,0,0,0,54.5,23.344,1.447,1.447,0,0,0,55.944,24.788Z"
              transform="translate(-54.5 -21.9)"
              fill="#259dc6"
            />
          </g>
          <g
            id="Group_3523"
            data-name="Group 3523"
            transform="translate(77.386 45.046)"
          >
            <path
              id="Path_10448"
              data-name="Path 10448"
              d="M55.944,37.488h2.888a1.444,1.444,0,1,0,0-2.888H55.944a1.444,1.444,0,1,0,0,2.888Z"
              transform="translate(-54.5 -34.6)"
              fill="#259dc6"
            />
          </g>
          <g
            id="Group_3524"
            data-name="Group 3524"
            transform="translate(77.386 54.286)"
          >
            <path
              id="Path_10449"
              data-name="Path 10449"
              d="M55.944,43.888h2.888a1.444,1.444,0,1,0,0-2.888H55.944a1.444,1.444,0,1,0,0,2.888Z"
              transform="translate(-54.5 -41)"
              fill="#f7c26f"
            />
          </g>
          <g
            id="Group_3525"
            data-name="Group 3525"
            transform="translate(77.386 63.526)"
          >
            <path
              id="Path_10450"
              data-name="Path 10450"
              d="M55.944,50.288h2.888a1.444,1.444,0,1,0,0-2.888H55.944a1.444,1.444,0,1,0,0,2.888Z"
              transform="translate(-54.5 -47.4)"
              fill="#259dc6"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>
