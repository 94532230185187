<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="54"
    height="54"
    viewBox="0 0 54 54"
  >
    <g
      id="Group_3669"
      data-name="Group 3669"
      transform="translate(-1213 -4381)"
    >
      <circle
        id="Ellipse_22"
        data-name="Ellipse 22"
        cx="27"
        cy="27"
        r="27"
        transform="translate(1213 4381)"
        fill="#fff"
      />
      <g
        id="Group_278"
        data-name="Group 278"
        transform="translate(-1.541 350.459)"
      >
        <circle
          id="Ellipse_21"
          data-name="Ellipse 21"
          cx="15"
          cy="15"
          r="15"
          transform="translate(1256.541 4072.541) rotate(180)"
          fill="none"
          stroke="#31418f"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
        <line
          id="Line_13"
          data-name="Line 13"
          x2="7"
          y2="7"
          transform="translate(1248.541 4057.541) rotate(180)"
          fill="none"
          stroke="#31418f"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
        <line
          id="Line_14"
          data-name="Line 14"
          y2="14"
          transform="translate(1241.541 4064.541) rotate(180)"
          fill="none"
          stroke="#31418f"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
        <line
          id="Line_15"
          data-name="Line 15"
          x1="7"
          y2="7"
          transform="translate(1241.541 4057.541) rotate(180)"
          fill="none"
          stroke="#31418f"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>
