<template>
  <div>
    <a
      class="whitespace-nowrap text-gray-500 hover:text-primary ml-2 px-3 py-2 flex items-center text-base"
      href="#"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      About Us <ChevronDownIcon class="text-gray-500 hover:text-primary ml-3" />
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow
      }"
    >
      <router-link
        to="/about-us"
        class="text-base py-2 px-4 block w-full whitespace-nowrap bg-transparent"
      >
        About Us
      </router-link>
      <a
        href="https://blog.talentfier.com/
"
        target="_blank"
        class="text-base py-2 px-4 block w-full whitespace-nowrap bg-transparent"
      >
        Blog
      </a>
      <router-link
        to="/contact-us"
        class="text-base py-2 px-4 block w-full whitespace-nowrap bg-transparent"
      >
        Contact Us
      </router-link>
    </div>
  </div>
</template>
<script>
import { createPopper } from "@popperjs/core";
import ChevronDownIcon from "@/components/Icons/ChevronDownIcon.vue";

export default {
  data() {
    return {
      dropdownPopoverShow: false
    };
  },
  components: {
    ChevronDownIcon
  },
  methods: {
    toggleDropdown: function(event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start"
        });
      }
    }
  }
};
</script>
