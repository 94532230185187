<template>
  <div>
    <Breadcrumb :crumbs="crumbs" @selected="selected">
      <div class="flex items-center justify-center">
        <div class="relative">
          <h2
            class="relative text-3xl md:text-7xl font-gilroy-bold text-center z-50"
          >
            Pricing
          </h2>
          <PricingIcon class="absolute -top-1/2 -left-1/3 z-0" />
        </div>
      </div>
    </Breadcrumb>

    <main>
      <div
        class="container relative py-16 flex flex-col content-center items-center justify-center"
      >
        <h1 class="text-primary-darkest text-xl lg:text-4.5xl font-gilroy-bold">
          Pick a Plan that Works for You
        </h1>
        <div class="flex justify-center flex-col items-center">
          <div
            class="border-2 border-primary rounded-full flex justify-around my-5 lg:my-12"
          >
            <button
              v-for="(price, frequency) in plans[0].pricing"
              :key="price"
              @click="currentFrequency = frequency"
              class="rounded-full text-base px-8 py-2 focus:outline-none"
              :class="
                currentFrequency == frequency ? 'bg-primary text-white' : ''
              "
            >
              <span v-if="frequency == 'monthly'"> Bill Monthly </span>
              <span v-else> Bill Annually (Save 15%) </span>
            </button>
          </div>
          <div class="pt-8 flex flex-col lg:flex-row w-full justify-between">
            <div
              v-for="plan in plans"
              :key="plan.id"
              class="flex flex-col w-full mb-5 lg:mb-0 max-w-sm text-center bg-white shadow-lg rounded-lg lg:mx-4"
            >
              <div
                :style="{
                  backgroundImage: 'url(' + packageBg + ')',
                  backgroundSize: '100% auto',
                }"
                class="w-full h-28 lg:h-48 flex items-center justify-center bg-no-repeat"
              >
                <h2
                  class="flex flex-col items-center justify-center px-2 text-primary-darkest"
                >
                  {{ plan.name }}
                  <!-- <span
                    v-if="plan.id == 1"
                    class="pt-2 text-base block text-primary"
                  >
                    1st time free!
                  </span> -->
                </h2>
              </div>
              <div
                class="relative flex flex-col justify-between h-full pb-28 items-center p-5"
              >
                <ul class="text-left pb-5 space-y-2 mb-5">
                  <li
                    v-for="(benefit, i) in plan.benefits[currentFrequency]"
                    :key="i"
                    class="text-gray-800 text-sm flex items-center"
                  >
                    <ListIcon class="mr-2" />
                    {{ benefit }}
                  </li>
                </ul>

                <!-- <span
                  v-if="plan.id == 1 && currentFrequency == 'monthly'"
                  class="text-gray-500 text-opacity-40 text-xs mb-5"
                  >No credit card required
                </span> -->
                <div
                  class="flex flex-col absolute bottom-5 border-t border-gray-20"
                >
                  <p class="my-3 text-sm font-gilroy-bold">
                    {{ plan.pricing[currentFrequency].price }}
                    {{ plan.pricing[currentFrequency].label }}
                  </p>

                  <span
                    v-if="currentFrequency == 'yearly'"
                    class="text-gray-500 text-opacity-40 text-xs mb-5"
                    >Billed Annually
                  </span>

                  <a
                    :href="plan.action.href"
                    class="px-12 py-2 text-base text-white bg-primary transition-colors rounded-full focus:outline-none"
                  >
                    {{ plan.action.title }}
                  </a>
                </div>
              </div>
            </div>

            <div
              class="flex flex-col w-full max-w-sm text-center bg-white shadow-lg rounded-lg lg:mx-4"
            >
              <div
                :style="{ backgroundImage: 'url(' + packageBg + ')' }"
                class="w-full h-28 lg:h-48 flex items-center justify-center bg-auto bg-no-repeat"
              >
                <h2
                  class="flex flex-col items-center justify-center px-2 text-primary-darkest"
                >
                  Custom
                </h2>
              </div>
              <div
                class="relative flex flex-col h-full pb-20 items-center justify-center p-5"
              >
                <p class="mb-5 text-gray-800 text-sm">Need bigger plans ?</p>
                <p class="text-gray-800 text-sm">Let’s Talk !</p>
                <a
                  href="/contact-us"
                  class="absolute bottom-5 px-12 py-2 text-base text-white bg-primary transition-colors rounded-full focus:outline-none"
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div>
          <p class="pt-12">
            * Basic level of Anti cheating / Proctoring tools is offered for
            free, Advanced techniques can be offered with extra fees
          </p>
          <router-link
            to="/coupon-request"
            class="px-12 py-2 text-base my-12 text-white bg-secondary-darker transition-colors rounded-full focus:outline-none"
          >
            Request a free trial coupon
          </router-link>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import PricingIcon from "@/components/Icons/PricingIcon.vue";
import ListIcon from "@/components/Icons/ListIcon.vue";
import packageBg from "@/assets/img/package-bg.png";

import { watchEffect } from "vue";
import { useMeta } from "vue-meta";

export default {
  setup() {
    // Add meta info
    // The object passed into useMeta is user configurable
    const { meta } = useMeta({
      title: "Pre-hiring tests prices",
    });

    watchEffect(() => {
      meta.description =
        "Pick the plan that best fit with your hiring needs monthly or yearly. We are always keen to keep it affordable and best value for money.";
    });
  },
  data() {
    return {
      packageBg,
      crumbs: ["Home", "Pricing"],
      currentFrequency: "monthly",
      plans: [
        {
          id: 1,
          name: "Starting",
          action: { title: "Start Now", href: "/sign-up" },

          benefits: {
            monthly: [
              "50 Tests",
              "Basic Anti cheating techniques",
              "Valid for 1 month",
            ],
            yearly: [
              "600 Tests",
              "Basic Anti cheating techniques",
              "Valid for 12 months",
            ],
          },
          pricing: {
            monthly: { price: 35, label: "USD / Month" },
            yearly: { price: 357, label: "USD / Year" },
          },
        },
        {
          id: 2,
          name: "Growing",
          action: { title: "Start Now", href: "/sign-up" },
          benefits: {
            monthly: [
              "100 Tests",
              "Basic & Advanced Anti cheating techniques",
              "Add your Own tests",
              "Valid for 1 month",
            ],
            yearly: [
              "1200 Tests",
              "Basic & Advanced Anti cheating techniques",
              "Add your Own tests",
              "Valid for 12 months",
            ],
          },
          pricing: {
            monthly: { price: 65, label: "USD / Month" },
            yearly: { price: 663, label: "USD / Year" },
          },
        },
        {
          id: 3,
          name: "Scaling",
          action: { title: "Start Now", href: "/sign-up" },
          benefits: {
            monthly: [
              "250 Tests",
              "Full Features of Anti cheating techniques",
              "Add your Own tests",
              "Valid for 1 month",
            ],
            yearly: [
              "3000 Tests",
              "Full Features of Anti cheating techniques",
              "Add your Own tests",
              "Valid for 12 months",
            ],
          },
          pricing: {
            monthly: { price: 160, label: "USD / Month" },
            yearly: { price: 1632, label: "USD / Year" },
          },
        },
      ],
    };
  },

  components: { Breadcrumb, PricingIcon, ListIcon },
  methods: {},
};
</script>
