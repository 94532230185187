<template>
  <div>
    <Breadcrumb :crumbs="crumbs" @selected="selected">
      <div class="flex items-center justify-center">
        <div class="relative">
          <h2
            class="relative text-3xl md:text-7xl
 font-gilroy-bold text-center z-50"
          >
            Request a free coupon
          </h2>
          <SignupIcon
            class="absolute -top-1/2 -left-1/3 z-0
"
          />
        </div>
      </div>
    </Breadcrumb>

    <main>
      <div
        class="container relative py-16 flex bg-contain bg-top bg-no-repeat flex-col content-center items-center justify-center"
        :style="{ backgroundImage: 'url(' + formsBg + ')' }"
      >
        <div
          class="w-full lg:w-8/12 flex-auto px-4 font-gilroy-regular
 rounded-lg shadow-lg bg-white lg:px-10 py-10"
        >
          <div class="py-5" v-if="formSubmitted">
            Thank you for your interest in our services,
            <span class="text-primary">{{ name }}</span
            >. we have received your request and will get back to you soon.
          </div>

          <form
            :class="{ formSubmitted: formSubmitted }"
            id="signup-form"
            v-if="!isLoading && !formSubmitted"
          >
            <div
              class="grid grid-cols-1 lg:grid-cols-2 gap-4 relative w-full mb-3"
            >
              <div>
                <input
                  type="text"
                  class="border-1.5 border-gray-50 px-3 py-3 placeholder-gray-500 placeholder-opacity-40 text-gray-500 bg-white rounded-full text-base lg:text-lg focus:outline-none w-full ease-linear transition-all duration-150"
                  v-model="v$.name.$model"
                  placeholder="Full Name"
                />
                <!-- Error Message -->
                <div v-for="(error, index) of v$.name.$errors" :key="index">
                  <div class="text-red-500">{{ error.$message }}</div>
                </div>
              </div>
              <div>
                <input
                  type="text"
                  class="border-1.5 border-gray-50 px-3 py-3 placeholder-gray-500 placeholder-opacity-40 text-gray-500 bg-white rounded-full text-base lg:text-lg focus:outline-none w-full ease-linear transition-all duration-150"
                  v-model="v$.mobile.$model"
                  placeholder="Mobile Number


"
                />
                <!-- Error Message -->
                <div v-for="(error, index) of v$.mobile.$errors" :key="index">
                  <div class="text-red-500">{{ error.$message }}</div>
                </div>
              </div>
            </div>
            <div
              class="grid grid-cols-1 lg:grid-cols-2 gap-4 relative w-full mb-3"
            >
              <div>
                <input
                  type="text"
                  class="border-1.5 border-gray-50 px-3 py-3 placeholder-gray-500 placeholder-opacity-40 text-gray-500 bg-white rounded-full text-base lg:text-lg focus:outline-none w-full ease-linear transition-all duration-150"
                  v-model="v$.company_website.$model"
                  placeholder="Company Website

"
                />
                <!-- Error Message -->
                <div
                  v-for="(error, index) of v$.company_website.$errors"
                  :key="index"
                >
                  <div class="text-red-500">{{ error.$message }}</div>
                </div>
              </div>
              <div>
                <input
                  type="email"
                  class="border-1.5 border-gray-50 px-3 py-3 placeholder-gray-500 placeholder-opacity-40 text-gray-500 bg-white rounded-full text-base lg:text-lg focus:outline-none w-full ease-linear transition-all duration-150"
                  v-model="v$.email.$model"
                  placeholder="Your Business Email

"
                />
                <!-- Error Message -->
                <div v-for="(error, index) of v$.email.$errors" :key="index">
                  <div class="text-red-500">{{ error.$message }}</div>
                </div>
              </div>
            </div>

            <div class="flex flex-col items-center justify-center mt-6">
              <vue-recaptcha
                v-if="showRecaptcha"
                @verify="recaptchaVerified"
                @expire="recaptchaExpired"
                @fail="recaptchaFailed"
                ref="vueRecaptcha"
                sitekey="6Ld-2PMeAAAAAGJOjXcx8HFULKxrRvsHDpF-xseY
"
              ></vue-recaptcha>

              <!-- Error Message -->
              <div class="text-red-500" v-if="showRobotError">
                Verification required
              </div>
            </div>
            <div class="text-center mt-6">
              <button
                class="bg-primary
 text-white active:bg-opacity-50 text-base px-6 py-3
rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1
w-full ease-linear transition-all duration-150 cursor-pointer"
                @click.prevent="requestCode"
                type="submit"
              >
                Send
              </button>
            </div>
          </form>
          <div
            class="w-full flex flex-col items-center justify-center"
            v-if="isLoading"
          >
            <div class="loading-container">
              <div class="loader">
                <LoadingIcon />
              </div>
              <h4 class="loading-text">SENDING...</h4>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import SignupIcon from "@/components/Icons/SignupIcon.vue";
import LoadingIcon from "@/components/Icons/LoadingIcon.vue";
import formsBg from "@/assets/img/forms-bg.png";
import emailjs from "emailjs-com";
import { VueRecaptcha } from "vue-recaptcha";
import useVuelidate from "@vuelidate/core";
import { required, minLength, numeric } from "@vuelidate/validators";

import { watchEffect } from "vue";
import { useMeta } from "vue-meta";
export function validName(name) {
  let validNamePattern = new RegExp("^[a-zA-Z]+(?:[-'\\s][a-zA-Z]+)*$");
  if (validNamePattern.test(name)) {
    return true;
  }
  return false;
}
export function validEmail(email) {
  let validEmailPattern = new RegExp(
    "^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$"
  );
  if (validEmailPattern.test(email)) {
    return true;
  }
  return false;
}
export function validUrl(company_website) {
  let validUrlPattern = new RegExp(
    // eslint-disable-next-line
    "^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$"
  ); // eslint-disable-line

  if (validUrlPattern.test(company_website)) {
    return true;
  }
  return false;
}
export default {
  setup() {
    // Add meta info
    // The object passed into useMeta is user configurable
    const { meta } = useMeta({
      title: "Coupon Request"
    });

    watchEffect(() => {
      meta.description =
        "Create your account, and start now to use pre-hiring tests to identify talent easier and make your hiring faster.";
    });
    return { v$: useVuelidate() };
  },
  data() {
    return {
      sitekey: process.env.VUE_APP_SITEKEY,
      robot: true,
      showRobotError: false,
      showRecaptcha: true,
      crumbs: ["Home", "Request a free trial coupon"],
      formsBg,
      company_website: "",
      email: "",
      name: "",
      mobile: "",
      formSubmitted: false,
      isLoading: false,
      formInvalid: false
    };
  },
  validations() {
    return {
      name: {
        required,
        name_validation: {
          $validator: validName,
          $message:
            "Invalid Name. Valid name only contain letters, dashes (-) and spaces"
        }
      },
      email: {
        required,
        email_validation: {
          $validator: validEmail,
          $message: "Invalid business email"
        }
      },
      // email: { required, email },
      company_website: {
        required,
        url_validation: {
          $validator: validUrl,
          $message: "Valid URL required"
        }
      },

      mobile: { required, numeric, min: minLength(11) }
    };
  },

  components: { Breadcrumb, SignupIcon, LoadingIcon, VueRecaptcha },
  methods: {
    selected(crumb) {
      console.log(crumb);
    },
    getURL(src) {
      // // console.log(path) return
      require(`@/assets/img/${src}`).default;
    },
    async requestCode() {
      const result = await this.v$.$validate();
      if (!result) {
        if (this.robot) {
          this.showRobotError = true;
          return;
        }
        console.log("invalid");

        return;
      } else {
        this.isLoading = true;
        this.formSubmitted = false;
        emailjs.init("user_izlOpqWP0Iwr5F0O3XBYL");
        this.applicant_number = (Math.random() * 100000) | 0;
        emailjs
          .send(
            "service_1zsupij", //get this from emailjs dashboard
            "template_eur5e9g", //get this from emailjs dashboard
            {
              company_website: this.company_website,
              email: this.email,
              name: this.name,
              mobile: this.mobile,
              applicant_number: this.applicant_number
            }
          )
          .then(
            response => {
              (this.formSubmitted = true),
                (this.isLoading = false),
                console.log("SUCCESS You just sent an email...", response);
            },
            error => {
              console.log("FAILED Throw an error to user...", error);
              this.isLoading = false;
            }
          );
      }
    },
    recaptchaVerified() {
      this.robot = false;
      this.showRobotError = false;
    },
    recaptchaExpired() {
      this.robot = true;
      this.$refs.vueRecaptcha.reset();
    },
    recaptchaFailed() {},

    validEmail: function(email) {
      var re = /^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/;
      return re.test(email);
    },
    validNumber: function(mobile) {
      var re = /^[0-9+]*$/;
      return re.test(mobile);
    },
    validText: function(input) {
      var re = /^[a-zA-Z\u0600-\u06FF ]+$/;
      return re.test(input);
    },
    validUrl: function(input) {
      var re = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
      return re.test(input);
    }
  }
};
</script>
