<template>
  <div
    class="w-full absolute top-0 left-0 right-0 bottom-0 bg-black z-50 bg-opacity-20 h-full
overflow-y-auto
"
  >
    <div
      class="relative flex flex-col bg-white w-full md:w-3/4 lg:w-1/2 mx-auto my-20 p-3
lg:p-8
"
    >
      <slot />
      <button
        class="absolute top-3 left-3 focus:outline-none bg-primary rounded-full w-5 h-5 flex items-center justify-center"
        @click="TogglePopup()"
      >
        <CloseIcon class="text-white" />
      </button>
    </div>
  </div>
</template>

<script>
import CloseIcon from "@/components/Icons/CloseIcon.vue";

export default {
  props: ["TogglePopup"],
  components: {
    CloseIcon
  }
};
</script>

<style scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.2);

  display: flex;
  align-items: center;
  justify-content: center;
}
.popup-inner {
  background: #fff;
  padding: 32px;
}
</style>
