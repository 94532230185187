<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="105.003"
    height="108.99"
    viewBox="0 0 105.003 108.99"
  >
    <g
      id="Group_3631"
      data-name="Group 3631"
      transform="translate(-12095 11645.19)"
    >
      <path
        id="Path_10646"
        data-name="Path 10646"
        d="M103.272,40.864,86.83,30.717a1.281,1.281,0,0,0-.237-.133l-.064-.03V26.482a2.145,2.145,0,0,0-.538-1.407L64.425,1.03A.391.391,0,0,0,64.34.956v0A2.037,2.037,0,0,0,62.826.3a1.388,1.388,0,0,0-.218.011A1.252,1.252,0,0,0,62.415.3H31.93a10.145,10.145,0,0,0-10.1,10.1V30.716L5.105,40.864A7.112,7.112,0,0,0,1.68,46.951V95.973a13.344,13.344,0,0,0,13.3,13.317h78.4a13.343,13.343,0,0,0,13.3-13.317V46.95A7.226,7.226,0,0,0,103.272,40.864ZM64.923,7.908l12.564,14H70.815a5.931,5.931,0,0,1-5.893-5.891ZM26.054,10.4A5.883,5.883,0,0,1,31.931,4.52H60.716v11.5a10.109,10.109,0,0,0,10.1,10.1H81.265l1.044,1.171V61.762L54.181,78.952,26.054,61.762ZM5.886,46.953a2.987,2.987,0,0,1,1.4-2.5l14.55-8.81V59.192L5.886,49.445Zm96.59,49.019a9.125,9.125,0,0,1-9.1,9.1h-78.4a9.124,9.124,0,0,1-9.1-9.1V54.384L22.491,64.521a1.828,1.828,0,0,0,.7.423l29.9,18.278a2.091,2.091,0,0,0,2.192,0L85.1,64.978a2.069,2.069,0,0,0,.714-.415l16.66-10.177Zm0-46.527L86.529,59.192V35.481l14.534,8.966a2.979,2.979,0,0,1,1.414,2.5Z"
        transform="translate(12093.32 -11645.49)"
        fill="#259dc6"
      />
      <path
        id="Path_10647"
        data-name="Path 10647"
        d="M65.066,36.213A19.743,19.743,0,1,0,45.323,55.956,19.765,19.765,0,0,0,65.066,36.213ZM45.323,51.736A15.53,15.53,0,1,1,60.846,36.213,15.548,15.548,0,0,1,45.323,51.736Z"
        transform="translate(12102.42 -11639.506)"
        fill="#31418f"
      />
      <path
        id="Path_10648"
        data-name="Path 10648"
        d="M49.617,26.754l-.012-.005a2.425,2.425,0,0,0-.822-.129h0a2.018,2.018,0,0,0-1.259.488L39.986,33.24l-2.578-3.035a2.117,2.117,0,0,0-3.613.66,2.083,2.083,0,0,0,.407,2.073l3.916,4.587a2.1,2.1,0,0,0,2.932.279l9.123-7.424a2.12,2.12,0,0,0-.555-3.627Z"
        transform="translate(12105.322 -11635.75)"
        fill="#f7c26f"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>
