<template>
  <div
    class="py-12 text-white bg-cover bg-no-repeat"
    :style="{ backgroundImage: 'url(' + breadcrumbBg + ')' }"
  >
    <ol class="container flex text-base">
      <li v-for="(crumb, ci) in crumbs" :key="ci">
        <router-link v-if="crumb == 'Home'" to="/">
          {{ crumb }}
        </router-link>
        <router-link
          v-else
          :class="{ 'text-opacity-40': isLast(ci) }"
          :to="`/` + crumb.replace(/\s+/g, '-')"
        >
          {{ crumb }}
        </router-link>
        <span v-if="!isLast(ci)" class="mx-2 inline-flex"><ChevronIcon /></span>
      </li>
    </ol>
    <div class="conteiner flex py-12 items-center justify-center">
      <slot />
    </div>
  </div>
</template>

<script>
import breadcrumbBg from "@/assets/img/breadcrumb-bg.png";
import ChevronIcon from "@/components/Icons/ChevronIcon.vue";

export default {
  data() {
    return { breadcrumbBg };
  },
  props: {
    crumbs: {
      type: Array,
      required: true
    }
  },
  methods: {
    isLast(index) {
      return index === this.crumbs.length - 1;
    },
    selected(crumb) {
      this.$emit("selected", crumb);
    },
    getURL(src) {
      // // console.log(path) return
      require(`@/assets/img/${src}`).default;
    }
  },
  components: {
    ChevronIcon
  }
};
</script>

<style scoped>
.breadcrumb {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.37rem;
}
</style>
