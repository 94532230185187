<template>
  <div>
    <Breadcrumb :crumbs="crumbs" @selected="selected">
      <div class="flex items-center justify-center">
        <div class="relative">
          <h2
            class="relative text-3xl md:text-7xl
 font-gilroy-bold text-center z-50"
          >
            How to Use
          </h2>
        </div>
      </div>
    </Breadcrumb>

    <main>
      <div
        class="container relative flex flex-col content-center items-center justify-center"
      >
        <div
          class="w-full bg-primary bg-opacity-10 rounded-b-3xl p-5 lg:p-12 grid grid-cols-1 lg:grid-cols-3 gap-4"
        >
          <div
            data-aos="zoom-in"
            v-for="step in steps"
            class="flex flex-col justify-center items-center p-5 rounded-lg hover:bg-white"
            :key="step.id"
          >
            <AssessmentIcon v-if="step.id == 1" />
            <CandidatesIcon v-if="step.id == 2" />
            <HireIcon v-if="step.id == 3" />

            <p class="text-primary-darkest text-1.5xl mt-5 text-center">
              {{ step.title }}
            </p>
            <p
              class="text-5.5xl text-primary font-gilroy-bold text-opacity-20 my-5"
            >
              {{ step.id }}
            </p>
          </div>
        </div>
        <!-- assessment -->
        <div class="w-full py-12 flex flex-col">
          <div class="flex flex-col lg:flex-row">
            <div
              data-aos="fade-down-right"
              class="w-full md:w-6/12
 text-lg"
            >
              <header class="flex w-full lg:w-1/2 my-18">
                <h1 class="relative flex text-primary-darkest text-1.5xl mt-5">
                  Create Assessment
                  <span
                    class="text-11xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-primary font-gilroy-bold text-opacity-10"
                  >
                    01
                  </span>
                </h1>
              </header>

              <ul class="flex flex-col mb-0 list-none flex-wrap pt-3 pb-4">
                <li
                  class="hover:border-primary hover:shadow-lg
 py-3 rounded-r-lg border-l-4"
                  :class="{
                    'border-white opacity-40': assessmentTab !== 1,
                    'border-primary shadow-lg': assessmentTab === 1
                  }"
                >
                  <a
                    class="px-5 py-3 block leading-normal cursor-pointer"
                    @click="toggleAssessmentTabs(1)"
                    @mouseover="toggleAssessmentTabs(1)"
                  >
                    <p>
                      Signup and enjoy the product tour to get familiar with
                      Talentfier
                    </p>
                  </a>
                </li>
                <li
                  class="hover:border-primary hover:shadow-lg
 py-3 rounded-r-lg border-l-4"
                  :class="{
                    'border-white opacity-40': assessmentTab !== 2,
                    'border-primary shadow-lg': assessmentTab === 2
                  }"
                >
                  <a
                    class="px-5 py-3 block
leading-normal cursor-pointer
"
                    @click="toggleAssessmentTabs(2)"
                    @mouseover="toggleAssessmentTabs(2)"
                  >
                    <p>
                      Create Your 1st assessment and define assessment name ,
                      and Target Position
                    </p>
                  </a>
                </li>
                <li
                  class="hover:border-primary hover:shadow-lg py-3 rounded-r-lg border-l-4"
                  :class="{
                    'border-white opacity-40': assessmentTab !== 3,
                    'border-primary shadow-lg': assessmentTab === 3
                  }"
                >
                  <a
                    class="px-5 py-3 block
leading-normal cursor-pointer
"
                    @click="toggleAssessmentTabs(3)"
                    @mouseover="toggleAssessmentTabs(3)"
                  >
                    <p>
                      Set pre-qualifying questions to eliminate disqualified
                      candidates (optional)
                    </p>
                  </a>
                </li>
                <li
                  class="hover:border-primary hover:shadow-lg py-3 rounded-r-lg border-l-4"
                  :class="{
                    'border-white opacity-40': assessmentTab !== 4,
                    'border-primary shadow-lg': assessmentTab === 4
                  }"
                >
                  <a
                    class="px-5 block
leading-normal cursor-pointer
"
                    @click="toggleAssessmentTabs(4)"
                    @mouseover="toggleAssessmentTabs(4)"
                  >
                    <p>
                      View and select the tests that you want to be in your
                      assessment (English - Technical - psychological)
                    </p>
                  </a>
                </li>

                <li
                  class="hover:border-primary hover:shadow-lg py-3 rounded-r-lg border-l-4"
                  :class="{
                    'border-white opacity-40': assessmentTab !== 5,
                    'border-primary shadow-lg': assessmentTab === 5
                  }"
                >
                  <a
                    class="px-5 py-3 block
leading-normal cursor-pointer
"
                    @click="toggleAssessmentTabs(5)"
                    @mouseover="toggleAssessmentTabs(5)"
                  >
                    <p>
                      Review and Save your assessment with your preferred
                      setting
                    </p>
                  </a>
                </li>
              </ul>
            </div>

            <div
              data-aos="fade-up-left"
              class="w-full md:w-6/12 tab-content tab-space"
            >
              <div
                :class="{
                  hidden: assessmentTab !== 1,
                  block: assessmentTab === 1
                }"
                class="flex items-center justify-end h-full"
              >
                <div class="bg-primary bg-opacity-10 p-5 lg:p-12 rounded">
                  <img :src="signupImg" alt="signup image" />
                </div>
              </div>
              <div
                :class="{
                  hidden: assessmentTab !== 2,
                  block: assessmentTab === 2
                }"
                class="flex items-center justify-end h-full"
              >
                <div class="bg-primary bg-opacity-10 p-5 lg:p-12 rounded">
                  <img :src="assessmentImg" alt="assessment image" />
                </div>
              </div>
              <div
                :class="{
                  hidden: assessmentTab !== 3,
                  block: assessmentTab === 3
                }"
                class="flex items-center justify-end h-full"
              >
                <div class="bg-primary bg-opacity-10 p-5 lg:p-12 rounded">
                  <img :src="screeningImg" alt="screening" />
                </div>
              </div>

              <div
                :class="{
                  hidden: assessmentTab !== 4,
                  block: assessmentTab === 4
                }"
                class="flex items-center justify-end h-full"
              >
                <div class="bg-primary bg-opacity-10 p-5 lg:p-12 rounded">
                  <img :src="testsImg" alt="tests" />
                </div>
              </div>

              <div
                :class="{
                  hidden: assessmentTab !== 5,
                  block: assessmentTab === 5
                }"
                class="flex items-center justify-end h-full"
              >
                <div class="bg-primary bg-opacity-10 p-5 lg:p-12 rounded">
                  <img :src="reviewImg" alt="review" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- candidates -->
        <div class="w-full py-12">
          <div class="flex flex-col lg:flex-row-reverse">
            <div
              data-aos="fade-up-left"
              class="w-full md:w-6/12
 text-lg"
            >
              <header class="flex w-full lg:w-1/2 my-18">
                <h1 class="relative flex text-primary-darkest text-1.5xl mt-5">
                  Invite Candidates

                  <span
                    class="text-11xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-primary font-gilroy-bold text-opacity-10"
                  >
                    02
                  </span>
                </h1>
              </header>

              <ul class="flex flex-col mb-0 list-none flex-wrap pt-3 pb-4">
                <li
                  class="hover:border-primary hover:shadow-lg
 py-3 rounded-r-lg border-l-4"
                  :class="{
                    'border-white opacity-40': candidatesTab !== 1,
                    'border-primary shadow-lg': candidatesTab === 1
                  }"
                >
                  <a
                    class="px-5 py-3 block leading-normal cursor-pointer"
                    @click="toggleCandidatesTabs(1)"
                    @mouseover="toggleCandidatesTabs(1)"
                  >
                    <p>
                      Generate one or many links for assessment as per you
                      hiring sources you want to track
                    </p>
                  </a>
                </li>
                <li
                  class="hover:border-primary hover:shadow-lg
 py-3 rounded-r-lg border-l-4"
                  :class="{
                    'border-white opacity-40': candidatesTab !== 2,
                    'border-primary shadow-lg': candidatesTab === 2
                  }"
                >
                  <a
                    class="px-5 py-3 block
leading-normal cursor-pointer
"
                    @click="toggleCandidatesTabs(2)"
                    @mouseover="toggleCandidatesTabs(2)"
                  >
                    <p>
                      Invite candidates by email if you want
                    </p>
                  </a>
                </li>
                <li
                  class="hover:border-primary hover:shadow-lg py-3 rounded-r-lg border-l-4"
                  :class="{
                    'border-white opacity-40': candidatesTab !== 3,
                    'border-primary shadow-lg': candidatesTab === 3
                  }"
                >
                  <a
                    class="px-5 py-3 block
leading-normal cursor-pointer
"
                    @click="toggleCandidatesTabs(3)"
                    @mouseover="toggleCandidatesTabs(3)"
                  >
                    <p>
                      Candidate receive assessment invitation on his email and
                      guiding tips
                    </p>
                  </a>
                </li>
                <li
                  class="hover:border-primary hover:shadow-lg py-3 rounded-r-lg border-l-4"
                  :class="{
                    'border-white opacity-40': candidatesTab !== 4,
                    'border-primary shadow-lg': candidatesTab === 4
                  }"
                >
                  <a
                    class="px-5 block
leading-normal cursor-pointer
"
                    @click="toggleCandidatesTabs(4)"
                    @mouseover="toggleCandidatesTabs(4)"
                  >
                    <p>
                      Candidate will be asked for accessing his webcam
                      permission
                    </p>
                  </a>
                </li>

                <li
                  class="hover:border-primary hover:shadow-lg py-3 rounded-r-lg border-l-4"
                  :class="{
                    'border-white opacity-40': candidatesTab !== 5,
                    'border-primary shadow-lg': candidatesTab === 5
                  }"
                >
                  <a
                    class="px-5 py-3 block
leading-normal cursor-pointer
"
                    @click="toggleCandidatesTabs(5)"
                    @mouseover="toggleCandidatesTabs(5)"
                  >
                    <p>
                      Candidate should pass pre-qualifying questions to take
                      assessment
                    </p>
                  </a>
                </li>
              </ul>
            </div>

            <div
              data-aos="fade-down-right"
              class="w-full md:w-6/12 tab-content tab-space"
            >
              <div
                :class="{
                  hidden: candidatesTab !== 1,
                  block: candidatesTab === 1
                }"
                class="flex items-center justify-start h-full"
              >
                <div
                  class="bg-primary-darkest bg-opacity-10 p-5 lg:p-12 rounded"
                >
                  <div class="bg-white shadow-lg rounded-lg p-5">
                    <img :src="candidates1Img" alt="candidates step1" />
                  </div>
                </div>
              </div>
              <div
                :class="{
                  hidden: candidatesTab !== 2,
                  block: candidatesTab === 2
                }"
                class="flex items-center justify-start h-full"
              >
                <div
                  class="bg-primary-darkest bg-opacity-10 p-5 lg:p-12 rounded"
                >
                  <div class="bg-white shadow-lg rounded-lg p-5">
                    <img :src="candidates2Img" alt="candidates step2" />
                  </div>
                </div>
              </div>
              <div
                :class="{
                  hidden: candidatesTab !== 3,
                  block: candidatesTab === 3
                }"
                class="flex items-center justify-start h-full"
              >
                <div
                  class="bg-primary-darkest bg-opacity-10 p-5 lg:p-12 rounded"
                >
                  <div class="bg-white shadow-lg rounded-lg p-5">
                    <img :src="candidates3Img" alt="candidates step3" />
                  </div>
                </div>
              </div>

              <div
                :class="{
                  hidden: candidatesTab !== 4,
                  block: candidatesTab === 4
                }"
                class="flex items-center justify-start h-full"
              >
                <div
                  class="bg-primary-darkest bg-opacity-10 p-5 lg:p-12 rounded"
                >
                  <div class="bg-white shadow-lg rounded-lg p-5">
                    <img :src="candidates4Img" alt="candidates step4" />
                  </div>
                </div>
              </div>

              <div
                :class="{
                  hidden: candidatesTab !== 5,
                  block: candidatesTab === 5
                }"
                class="flex items-center justify-start h-full"
              >
                <div
                  class="bg-primary-darkest bg-opacity-10 p-5 lg:p-12 rounded"
                >
                  <div class="bg-white shadow-lg rounded-lg p-5">
                    <img :src="candidates5Img" alt="candidates step4" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- hire -->
        <div class="w-full py-12 flex flex-col">
          <div class="flex flex-col lg:flex-row">
            <div
              data-aos="fade-down-right"
              class="w-full md:w-6/12
 text-lg"
            >
              <header class="flex w-full lg:w-1/2 my-18">
                <h1 class="relative flex text-primary-darkest text-1.5xl mt-5">
                  Contact and hire top talents

                  <span
                    class="text-11xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-primary font-gilroy-bold text-opacity-10"
                  >
                    03
                  </span>
                </h1>
              </header>

              <ul class="flex flex-col mb-0 list-none flex-wrap pt-3 pb-4">
                <li
                  class="hover:border-primary hover:shadow-lg
 py-3 rounded-r-lg border-l-4"
                  :class="{
                    'border-white opacity-40': hireTab !== 1,
                    'border-primary shadow-lg': hireTab === 1
                  }"
                >
                  <a
                    class="px-5 py-3 block leading-normal cursor-pointer"
                    @click="toggleHireTabs(1)"
                    @mouseover="toggleHireTabs(1)"
                  >
                    <p>
                      Use your dashboard to view best fit candidates first
                    </p>
                  </a>
                </li>
                <li
                  class="hover:border-primary hover:shadow-lg
 py-3 rounded-r-lg border-l-4"
                  :class="{
                    'border-white opacity-40': hireTab !== 2,
                    'border-primary shadow-lg': hireTab === 2
                  }"
                >
                  <a
                    class="px-5 py-3 block
leading-normal cursor-pointer
"
                    @click="toggleHireTabs(2)"
                    @mouseover="toggleHireTabs(2)"
                  >
                    <p>
                      View results in brief or in details as you like
                    </p>
                  </a>
                </li>
                <li
                  class="hover:border-primary hover:shadow-lg py-3 rounded-r-lg border-l-4"
                  :class="{
                    'border-white opacity-40': hireTab !== 3,
                    'border-primary shadow-lg': hireTab === 3
                  }"
                >
                  <a
                    class="px-5 py-3 block
leading-normal cursor-pointer
"
                    @click="toggleHireTabs(3)"
                    @mouseover="toggleHireTabs(3)"
                  >
                    <p>
                      Update easily the status for candidate for later follow-up
                    </p>
                  </a>
                </li>
                <li
                  class="hover:border-primary hover:shadow-lg py-3 rounded-r-lg border-l-4"
                  :class="{
                    'border-white opacity-40': hireTab !== 4,
                    'border-primary shadow-lg': hireTab === 4
                  }"
                >
                  <a
                    class="px-5 block
leading-normal cursor-pointer
"
                    @click="toggleHireTabs(4)"
                    @mouseover="toggleHireTabs(4)"
                  >
                    <p>
                      You can delete and re-invite candidate if needed
                    </p>
                  </a>
                </li>

                <li
                  class="hover:border-primary hover:shadow-lg py-3 rounded-r-lg border-l-4"
                  :class="{
                    'border-white opacity-40': hireTab !== 5,
                    'border-primary shadow-lg': hireTab === 5
                  }"
                >
                  <a
                    class="px-5 py-3 block
leading-normal cursor-pointer
"
                    @click="toggleHireTabs(5)"
                    @mouseover="toggleHireTabs(5)"
                  >
                    <p>
                      Send feedback to candidate easily with just one click
                    </p>
                  </a>
                </li>
              </ul>
            </div>

            <div
              data-aos="fade-up-left"
              class="w-full md:w-6/12 tab-content tab-space"
            >
              <div
                :class="{
                  hidden: hireTab !== 1,
                  block: hireTab === 1
                }"
                class="flex items-center justify-end h-full"
              >
                <div
                  class="bg-white border-gray-50 p-5 lg:p-12 rounded flex flex-col items-center"
                >
                  <img :src="hire1Img" alt="hire step1" />
                  <p class="text-2.5xl text-gray-500 my-5 text-center">
                    Use your dashboard
                  </p>
                </div>
              </div>
              <div
                :class="{
                  hidden: hireTab !== 2,
                  block: hireTab === 2
                }"
                class="flex items-center justify-end h-full"
              >
                <div
                  class="bg-white border-gray-50 p-5 lg:p-12 rounded flex flex-col items-center"
                >
                  <img :src="hire2Img" alt="hire step2" />
                  <p class="text-2.5xl text-gray-500 my-5 text-center">
                    View results in brief
                  </p>
                </div>
              </div>
              <div
                :class="{
                  hidden: hireTab !== 3,
                  block: hireTab === 3
                }"
                class="flex items-center justify-end h-full"
              >
                <div
                  class="bg-white border-gray-50 p-5 lg:p-12 rounded flex flex-col items-center"
                >
                  <img :src="hire3Img" alt="hire step3" />
                  <p class="text-2.5xl text-gray-500 my-5 text-center">
                    Update easily the status for candidate
                  </p>
                </div>
              </div>

              <div
                :class="{
                  hidden: hireTab !== 4,
                  block: hireTab === 4
                }"
                class="flex items-center justify-end h-full"
              >
                <div
                  class="bg-white border-gray-50 p-5 lg:p-12 rounded flex flex-col items-center"
                >
                  <img :src="hire4Img" alt="hire step4" />
                  <p class="text-2.5xl text-gray-500 my-5 text-center">
                    You can delete and re-invite
                  </p>
                </div>
              </div>

              <div
                :class="{
                  hidden: hireTab !== 5,
                  block: hireTab === 5
                }"
                class="flex items-center justify-end h-full"
              >
                <div
                  class="bg-white border-gray-50 p-5 lg:p-12 rounded flex flex-col items-center"
                >
                  <img :src="hire5Img" alt="hire step5" />
                  <p class="text-2.5xl text-gray-500 my-5 text-center">
                    Send feedback
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import AssessmentIcon from "@/components/Icons/AssessmentIcon.vue";
import CandidatesIcon from "@/components/Icons/CandidatesIcon.vue";
import HireIcon from "@/components/Icons/HireIcon.vue";
// assessment imgs
import signupImg from "@/assets/img/signup.png";
import assessmentImg from "@/assets/img/assessment.png";
import screeningImg from "@/assets/img/screening.png";
import testsImg from "@/assets/img/tests.png";
import reviewImg from "@/assets/img/review.png";
// candidates imgs
import candidates1Img from "@/assets/img/candidates1.png";
import candidates2Img from "@/assets/img/candidates2.png";
import candidates3Img from "@/assets/img/candidates3.png";
import candidates4Img from "@/assets/img/candidates4.png";
import candidates5Img from "@/assets/img/candidates5.png";

// candidates imgs
import hire1Img from "@/assets/img/hire1.png";
import hire2Img from "@/assets/img/hire2.png";
import hire3Img from "@/assets/img/hire3.png";
import hire4Img from "@/assets/img/hire4.png";
import hire5Img from "@/assets/img/hire5.png";
import { watchEffect } from "vue";
import { useMeta } from "vue-meta";

export default {
  setup() {
    // Add meta info
    // The object passed into useMeta is user configurable
    const { meta } = useMeta({
      title: "How to make faster hiring with pre-hiring tests "
    });

    watchEffect(() => {
      meta.description =
        "Three easy steps to find talents faster. Select relevant tests, send the link to applicants then pick top-scored candidates for hiring or final interviews.";
    });
  },
  data() {
    return {
      assessmentTab: 1,
      candidatesTab: 1,
      hireTab: 1,
      crumbs: ["Home", "How to use"],
      signupImg,
      assessmentImg,
      screeningImg,
      testsImg,
      reviewImg,
      candidates1Img,
      candidates2Img,
      candidates3Img,
      candidates4Img,
      candidates5Img,
      hire1Img,
      hire2Img,
      hire3Img,
      hire4Img,
      hire5Img,

      steps: [
        {
          id: 1,
          title: "Create Assessment",
          icon: ""
        },
        { id: 2, title: "Invite Candidates", icon: "" },

        { id: 3, title: "Contact and hire top talents", icon: "" }
      ]
    };
  },

  components: { Breadcrumb, AssessmentIcon, CandidatesIcon, HireIcon },
  methods: {
    toggleAssessmentTabs(tabNumber) {
      this.assessmentTab = tabNumber;
    },
    toggleCandidatesTabs(tabNumber) {
      this.candidatesTab = tabNumber;
    },

    toggleHireTabs(tabNumber) {
      this.hireTab = tabNumber;
    }
  }
};
</script>
