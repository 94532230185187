<template>
  <div>
    <Breadcrumb :crumbs="crumbs" @selected="selected">
      <div class="flex items-center justify-center">
        <div class="relative">
          <h2
            class="relative text-3xl md:text-7xl
 font-gilroy-bold text-center z-50"
          >
            Contact Us
          </h2>
          <ContactIcon
            class="absolute -top-1/2 -left-1/3 z-0
"
          />
        </div>
      </div>
    </Breadcrumb>

    <main>
      <div
        class="container relative py-16 flex bg-contain bg-top bg-no-repeat flex-col content-center items-center justify-center"
        :style="{ backgroundImage: 'url(' + formsBg + ')' }"
      >
        <h1 class="text-primary-darkest text-lg lg:text-3xl">
          Let’s create Progress
        </h1>
        <h2
          class="text-primary text-3xl lg:text-7.5xl leading-loose font-gilroy-bold"
        >
          Together
        </h2>
        <div
          class="w-full lg:w-8/12 flex-auto px-4 font-gilroy-regular
 rounded-lg shadow-lg bg-white lg:px-10 py-10"
        >
          <div class="py-5" v-if="formSubmitted">
            Thank you for contacting me,
            <span class="text-primary">{{ first_name }}</span
            >. We have received your message and will get back to you soon.
          </div>

          <form
            :class="{ formSubmitted: formSubmitted }"
            id="contact-form"
            v-if="!isLoading && !formSubmitted"
          >
             <div class="text-red-500 py-5">
              
            <p v-if="errors.length">
            <b>Please correct the following error(s):</b>
            <ul>
              <li v-for="(error,i) in errors" :key="i">{{ error }}</li>
            </ul>
          </p>
</div>

            <div class="relative w-full mb-3">
              <input
                type="text"
                v-model="subject"
                class="border-1.5 border-gray-50 px-3 py-3 placeholder-gray-500 placeholder-opacity-40 text-gray-500 bg-white rounded-full text-base lg:text-lg focus:outline-none w-full ease-linear transition-all duration-150"
                placeholder="Subject
"
              />
            </div>
            <div class="relative w-full mb-3">
              <input
                type="email"
                v-model="email"
                class="border-1.5 border-gray-50 px-3 py-3 placeholder-gray-500 placeholder-opacity-40 text-gray-500 bg-white rounded-full text-base lg:text-lg focus:outline-none w-full ease-linear transition-all duration-150"
                placeholder="Your Business Email

"
              />
            </div>
            <div class="relative mb-3 w-full flex flex-wrap items-stretch">
              <!-- <span
                class="absolute z-10 py-3 px-3 border-r border-gray-50 h-full text-base lg:text-lg text-opacity-40 font-normal text-gray-500 text-center flex items-center justify-center"
              >
                +20
              </span> -->
              <input
                type="text"
                class="border-1.5 border-gray-50 px-3 py-3 placeholder-gray-500 placeholder-opacity-40 text-gray-500 bg-white rounded-full text-base lg:text-lg focus:outline-none w-full ease-linear transition-all duration-150"
                v-model="mobile"
                placeholder="Mobile Number


"
              />
            </div>

            <div
              class="grid grid-cols-1 lg:grid-cols-2 gap-4 relative w-full mb-3"
            >
              <input
                type="text"
                v-model="first_name"
                class="border-1.5 border-gray-50 px-3 py-3 placeholder-gray-500 placeholder-opacity-40 text-gray-500 bg-white rounded-full text-base lg:text-lg focus:outline-none w-full ease-linear transition-all duration-150"
                placeholder="First Name



"
              />
              <input
                type="text"
                v-model="last_name"
                class="border-1.5 border-gray-50 px-3 py-3 placeholder-gray-500 placeholder-opacity-40 text-gray-500 bg-white rounded-full text-base lg:text-lg
 focus:outline-none w-full ease-linear transition-all duration-150"
                placeholder="Last Name



"
              
              />
            </div>
            <div class="relative w-full mb-3">
              <textarea
                rows="5"
                v-model="message"
                placeholder="Please Let us know How Can we Help You!"
                class="border-1.5 border-gray-50 px-3 py-3 placeholder-gray-500
placeholder-opacity-40 text-gray-500 bg-white rounded-3xl text-base lg:text-lg

focus:outline-none w-full ease-linear transition-all duration-150"
              ></textarea>
            </div>

            <div class="text-center mt-6">
              <input
                class="bg-primary text-white active:bg-opacity-50 text-base px-6 py-3
rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1
w-full ease-linear transition-all duration-150 cursor-pointer"
                v-on:click="submit"
                type="submit"
                value="Submit"
              />
            </div>
          </form>
          <div
            class="w-full flex flex-col items-center justify-center"
            v-if="isLoading"
          >
            <div class="loading-container">
              <div class="loader">
                <LoadingIcon />
              </div>
              <h4 class="loading-text">SENDING...</h4>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import ContactIcon from "@/components/Icons/ContactIcon.vue";
import LoadingIcon from "@/components/Icons/LoadingIcon.vue";
import formsBg from "@/assets/img/forms-bg.png";
import emailjs from "emailjs-com";
import { watchEffect } from "vue";
import { useMeta } from "vue-meta";

export default {
  setup() {
    // Add meta info
    // The object passed into useMeta is user configurable
    const { meta } = useMeta({
      title: "Contact Us"
    });

    watchEffect(() => {
      meta.description = "TalentFier App Contact Us";
    });
  },
  data() {
    return {
      errors: [],

      subject: "",
      email: "",
      mobile: "",
      first_name: "",
      last_name: "",
      message: "",
      formSubmitted: false,
      isLoading: false,
      formInvalid: false,

      crumbs: ["Home", "Contact Us"],
      formsBg
    };
  },

  components: { Breadcrumb, ContactIcon, LoadingIcon },
  methods: {
    selected(crumb) {
      console.log(crumb);
    },
    getURL(src) {
      // // console.log(path) return
      require(`@/assets/img/${src}`).default;
    },

    submit(e) {
      e.preventDefault();
      this.errors = [];

      if (!this.subject) {
        this.errors.push("Subject required.");
      }
      if (!this.email) {
        this.errors.push("Email required.");
      } else if (!this.validEmail(this.email)) {
        this.errors.push("Valid email required.");
      }
      if (!this.first_name) {
        this.errors.push("First Name required.");
      }else if (!this.validText(this.first_name)) {
        this.errors.push("Valid text only first name required.");
      }
      
      if (!this.last_name) {
        this.errors.push("Last Name required.");
      }else if (!this.validText(this.last_name)) {
        this.errors.push("Valid text only last name required.");
      }
      if (!this.mobile) {
        this.errors.push("Mobile required.");
      }
      else if (!this.validNumber(this.mobile)) {
        this.errors.push("Valid Mobile number with no spaces required.");
      }
      if (!this.message) {
        this.errors.push("Message required.");
      } else {
        if (!this.errors.length) {
          this.isLoading = true;
          this.formSubmitted = false;
          emailjs.init("user_izlOpqWP0Iwr5F0O3XBYL");
          this.contact_number = (Math.random() * 100000) | 0;
          emailjs
            .send(
              "service_1zsupij", //get this from emailjs dashboard
              "template_oijfl5k", //get this from emailjs dashboard
              {
                subject: this.subject,
                email: this.email,
                mobile: this.mobile,
                first_name: this.first_name,
                last_name: this.last_name,
                message: this.message,
                contact_number: this.contact_number
              }
            )
            .then(
              response => {
                (this.formSubmitted = true),
                  (this.isLoading = false),
                  console.log("SUCCESS You just sent an email...", response);
              },
              error => {
                console.log("FAILED Throw an error to user...", error);
                this.isLoading = false;
              }
            );
        }
      }
    },
    filterKey(e) {
      const key = e.key;

      // If is '.' key, stop it
      if (key === ".") return e.preventDefault();

      // OPTIONAL
      // If is 'e' key, stop it
      if (key === "e") return e.preventDefault();
    },

    // This can also prevent copy + paste invalid character
    filterInput(e) {
      e.target.value = e.target.value.replace(/[^0-9]+/g, "");
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    validEmail: function(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validNumber: function(mobile) {
      var re = /^[0-9+]*$/;
      return re.test(mobile);
    },
    validText: function(input) {
       var re =/^[a-zA-Z\u0600-\u06FF ]+$/;
      return re.test(input);
    }
  }
};
</script>
