import { createApp } from "vue";
// import { createWebHistory, createRouter } from "vue-router";
import VueSmoothScroll from "vue3-smooth-scroll";
import { createMetaManager } from "vue-meta";
import router from "./router";

const app = createApp();
app.use(VueSmoothScroll);

// styles

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

// mouting point for the whole app

import App from "@/App.vue";

createApp(App)
  .use(router)
  .use(createMetaManager())
  .mount("#talentfierApp");
