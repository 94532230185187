<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="77.622"
    height="81.415"
    viewBox="0 0 77.622 81.415"
  >
    <g id="noun_clock_3168982" transform="translate(0 0)">
      <g id="Group_3616" data-name="Group 3616" transform="translate(0 0)">
        <g id="Group_3615" data-name="Group 3615" transform="translate(0 0)">
          <path
            id="Path_10624"
            data-name="Path 10624"
            d="M130.958,296.122l-7.286,3.3a18.957,18.957,0,0,1-7.882,1.7,20.607,20.607,0,0,1-9.156-2.27,21.52,21.52,0,0,0-28.4,10.915l-4.76,10.72a1.765,1.765,0,0,0,.9,2.328l14,6.226a1.765,1.765,0,0,0,2.33-.9l2.84-6.4v0l0,0,1.293-2.914a4.194,4.194,0,0,1,5.537-2.128,22.594,22.594,0,0,0,18.529-.067l17.716-8.032a6.854,6.854,0,1,0-5.661-12.485ZM88.192,325.106l-10.775-4.792,1.447-3.26,10.746,4.856Zm46.97-19.714-17.716,8.032a19.07,19.07,0,0,1-15.638.056,7.726,7.726,0,0,0-10.2,3.919l-.57,1.285L80.3,313.829l1.164-2.622a17.989,17.989,0,0,1,23.74-9.124,23.96,23.96,0,0,0,10.59,2.574,22.464,22.464,0,0,0,9.339-2.019l7.286-3.3a3.325,3.325,0,1,1,2.747,6.057Z"
            transform="translate(-65.854 -247.786)"
            fill="#259dc6"
          />
          <ellipse
            id="Ellipse_65"
            data-name="Ellipse 65"
            cx="1.793"
            cy="1.793"
            rx="1.793"
            ry="1.793"
            transform="translate(1.789 63.536)"
            fill="#259dc6"
          />
          <ellipse
            id="Ellipse_66"
            data-name="Ellipse 66"
            cx="1.793"
            cy="1.793"
            rx="1.793"
            ry="1.793"
            transform="translate(74.035 40.479)"
            fill="#259dc6"
          />
          <ellipse
            id="Ellipse_67"
            data-name="Ellipse 67"
            cx="1.793"
            cy="1.537"
            rx="1.793"
            ry="1.537"
            transform="translate(72.498 2.05)"
            fill="#259dc6"
          />
          <ellipse
            id="Ellipse_68"
            data-name="Ellipse 68"
            cx="1.793"
            cy="1.793"
            rx="1.793"
            ry="1.793"
            transform="translate(22.797 35.355)"
            fill="#259dc6"
          />
          <ellipse
            id="Ellipse_69"
            data-name="Ellipse 69"
            cx="1.793"
            cy="1.793"
            rx="1.793"
            ry="1.793"
            transform="translate(5.376 23.057)"
            fill="#259dc6"
          />
          <ellipse
            id="Ellipse_70"
            data-name="Ellipse 70"
            cx="1.793"
            cy="1.793"
            rx="1.793"
            ry="1.793"
            transform="translate(24.846)"
            fill="#259dc6"
          />
          <path
            id="Path_10625"
            data-name="Path 10625"
            d="M376.294,405.494a5.294,5.294,0,1,0,5.294,5.294A5.3,5.3,0,0,0,376.294,405.494Zm0,7.059a1.765,1.765,0,1,1,1.765-1.765A1.767,1.767,0,0,1,376.294,412.553Z"
            transform="translate(-311.001 -338.375)"
            fill="#259dc6"
          />
          <path
            id="Path_10626"
            data-name="Path 10626"
            d="M39.823,299.906a4.412,4.412,0,1,0-4.412,4.412A4.417,4.417,0,0,0,39.823,299.906Zm-5.294,0a.882.882,0,1,1,.882.882A.883.883,0,0,1,34.529,299.906Z"
            transform="translate(-31 -247.787)"
            fill="#259dc6"
          />
          <path
            id="Path_10627"
            data-name="Path 10627"
            d="M191,48.435A22.865,22.865,0,0,0,197.582,64.5a1.634,1.634,0,0,0,.294.294A22.929,22.929,0,0,0,230.3,32.369a1.644,1.644,0,0,0-.292-.292A22.929,22.929,0,0,0,191,48.435Zm8.858-11.587a1.765,1.765,0,1,0,2.5-2.5l-.828-.828a19.325,19.325,0,0,1,10.65-4.42v1.18a1.765,1.765,0,1,0,3.529,0V29.1a19.324,19.324,0,0,1,10.65,4.42l-.828.828a1.765,1.765,0,0,0,2.5,2.5l.828-.828a19.327,19.327,0,0,1,4.42,10.65h-1.18a1.765,1.765,0,1,0,0,3.529h1.18a19.328,19.328,0,0,1-4.42,10.65l-.828-.828a1.765,1.765,0,0,0-2.5,2.5l.828.828a19.327,19.327,0,0,1-10.65,4.42v-1.18a1.765,1.765,0,1,0-3.529,0v1.18a19.326,19.326,0,0,1-10.65-4.42l.828-.828a1.765,1.765,0,1,0-2.5-2.5l-.828.828a19.326,19.326,0,0,1-4.42-10.65h1.18a1.765,1.765,0,0,0,0-3.529h-1.18a19.325,19.325,0,0,1,4.42-10.65Z"
            transform="translate(-162.765 -25.433)"
            fill="#259dc6"
          />
          <path
            id="Path_10628"
            data-name="Path 10628"
            d="M283.281,137.413a1.765,1.765,0,0,0,2.037.331l6.05-3.025a1.765,1.765,0,1,0-1.578-3.157l-4.913,2.456-5.151-5.151a1.765,1.765,0,0,0-2.5,2.5Z"
            transform="translate(-233.353 -110.139)"
            fill="#259dc6"
          />
          <path
            id="Path_10629"
            data-name="Path 10629"
            d="M88.059,69.612A7.059,7.059,0,1,0,81,62.553,7.067,7.067,0,0,0,88.059,69.612Zm0-10.588a3.529,3.529,0,1,1-3.529,3.529A3.533,3.533,0,0,1,88.059,59.023Z"
            transform="translate(-72.177 -50.139)"
            fill="#259dc6"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>
