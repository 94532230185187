<template>
  <div>
    <section class="header relative" style="height: calc(100vh - 100px)">
      <img
        class="absolute top-0 b-auto left-0 w-full z-0 sm:mt-0 md:w-1/2
"
        :src="introBg"
        alt="intro background"
      />

      <div
        class="container pt-5 lg:pt-20 justify-between z-10 flex flex-col md:flex-row"
      >
        <div
          data-aos="flip-left"
          class="flex flex-col justify-center lg:pr-12 w-full lg:w-1/2 text-white z-10 mb-12 lg:mb-0

"
        >
          <h4 class="text-lg md:text-3xl">Find The</h4>
          <h2 class="font-gilroy-bold text-2xl md:text-7xl">
            BEST, FAST
          </h2>
          <p class="mt-2 md:mt-4 text-base md:text-lg lg:leading-relaxed">
            Automate your screening and evaluation process, and make hiring
            faster, easier, and more accurate.
          </p>
          <div class="mt-5 md:mt-12 flex items-center">
            <router-link
              to="/sign-up"
              class="font-gilroy-bold text-primary bg-white p-3 md:px-6 md:py-4 rounded-full outline-none focus:outline-none mr-1 mb-1 bg-emerald-500 active:bg-emerald-600 text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
            >
              Start Free Trial
            </router-link>
            <ArrowIcon class="ml-2 md:ml-5 text-white" />
          </div>
        </div>
        <div data-aos="flip-right" class="w-full lg:w-1/2">
          <iframe
            class="rounded-lg w-full"
            height="315"
            src="https://www.youtube.com/embed/zXWfyCxeVHE"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>

      <router-link
        to="#about"
        class="hidden lg:block hover:bottom-2 ease-linear transition-all duration-150
 absolute bottom-0 left-1/2"
        ><ScrollIcon
      /></router-link>
    </section>

    <section id="about" class="relative py-5 md:py-16 items-center flex">
      <img
        data-aos="fade-up-left"
        class="hidden md:block absolute top-0 b-auto right-0 pt-16 sm:w-6/12 z-0 sm:mt-0 w-10/12 max-h-860-px"
        :src="aboutBg"
        alt="about background"
      />

      <div class="container flex justify-between z-50">
        <div class="w-full md:w-5/12">
          <div data-aos="fade-down-right" class="pt-0 lg:pt-12">
            <h2
              class="text-xl lg:text-7.5xl font-gilroy-bold text-primary my-5 lg:my-12"
            >
              Why
            </h2>
            <h1
              class="text-primary-darkest font-gilroy-bold text-lg lg:text-4.5xl leading-snug my-5"
            >
              Pre-hiring <br class="hidden md:block" />Test?
            </h1>
            <div
              v-for="(feature, i) in features"
              :key="i"
              class="my-5 md:my-8 flex items-center "
            >
              <BulletIcon class="mr-5 flex-shrink-0" />
              <p class="text-lg">
                {{ feature.text }}
              </p>
            </div>
            <router-link
              to="/sign-up"
              class="bg-primary text-white px-8 py-4 rounded-full outline-none focus:outline-none mr-1 mb-1 text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
            >
              Start Free Trial
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <!-- calculator -->
    <section
      class="py-20 px-6 bg-no-repeat bg-cover md:bg-center text-white"
      :style="{
        backgroundImage: 'url(' + calculatorBg + ')'
      }"
    >
      <div
        class="container flex flex-col lg:flex-row lg:justify-between lg:items-center"
      >
        <div
          data-aos="fade-down-right"
          class="w-full lg:w-1/2 flex items-center"
        >
          <img
            class="hidden md:block w-1/2"
            :src="calculatorImg"
            alt="calculator image"
          />
          <h2
            class="text-3xl mb-5 lg:mb-0 text-center md:text-left lg:text-7.5xl font-gilroy-bold md:leading-10 md:ml-5"
          >
            <span class="text-xl lg:text-4.5xl md:leading-loose">Savings </span>
            Calculator
          </h2>
        </div>
        <div data-aos="fade-up-left" class="w-full lg:w-1/3 flex flex-col">
          <p>Avg number of vacancies monthly</p>
          <div class="flex my-5">
            <input
              type="number"
              v-model="vacancies"
              @change="updateVacancies()"
              class="border-0 px-3 py-3 placeholder-gray-500 text-gray-500 text-opacity-40 bg-white rounded-full text-xl md:text-3xl shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              placeholder=""
            />
          </div>
          <div>
            <p>Expected Yearly Savings:</p>
            <div class="w-full bg-secondary rounded p-5 flex my-5">
              <span
                class="whitespace-nowrap w-1/2 px-2 lg:px-5 border-r border-secondary-darker"
                >{{ vacancies * 12 * 1500 }} EGP
              </span>
              <span class="whitespace-nowrap w-1/2 px-2 lg:px-5"
                >{{ vacancies * 12 * 21 }} Hours
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- tests -->
    <section class="relative">
      <div class="container py-12 lg:py-28">
        <h1 class="text-lg md:text-3xl leading-relaxed">
          Pre-Hiring
        </h1>

        <h2
          class="font-gilroy-bold text-primary text-3xl md:text-7.5xl md:leading-snug"
        >
          Test <span class="text-primary-darkest">Types</span>
        </h2>
        <div class="flex flex-col-reverse lg:flex-row">
          <div
            data-aos="fade-down-right"
            class="w-full md:w-6/12
 text-lg"
          >
            <ul class="flex flex-col mb-0 list-none flex-wrap pt-3 pb-4">
              <li
                class="hover:border-primary hover:shadow-lg
 my-2 md:my-5 py-2 md:py-5 rounded-r-lg border-l-4"
                :class="{
                  'border-white opacity-40': openTab !== 1,
                  'border-primary shadow-lg': openTab === 1
                }"
              >
                <a
                  class="px-3 md:px-5 py-3 block leading-normal cursor-pointer"
                  @click="toggleTabs(1)"
                  @mouseover="toggleTabs(1)"
                >
                  <h3
                    class="text-lg md:text-3xl"
                    :class="{
                      'text-primary-darkest': openTab === 1
                    }"
                  >
                    Technical
                  </h3>
                  <p class="text-base md:text-lg">
                    Skilled team is the backbone of organization success, so
                    pick top qualified talents to join your team
                  </p>
                </a>
              </li>
              <li
                class="hover:border-primary hover:shadow-lg
 my-5 py-5 rounded-r-lg border-l-4"
                :class="{
                  'border-white opacity-40': openTab !== 2,
                  'border-primary shadow-lg': openTab === 2
                }"
              >
                <a
                  class="px-5 py-3 block
leading-normal cursor-pointer
"
                  @click="toggleTabs(2)"
                  @mouseover="toggleTabs(2)"
                >
                  <h3
                    class="text-lg md:text-3xl"
                    :class="{ 'text-primary-darkest': openTab === 2 }"
                  >
                    English
                  </h3>
                  <p class="text-base md:text-lg">
                    Ensure your future employees have the suitable English
                    proficiency level for your work.
                  </p>
                </a>
              </li>
              <li
                class="hover:border-primary hover:shadow-lg
my-5 py-5 rounded-r-lg border-l-4"
                :class="{
                  'border-white opacity-40': openTab !== 3,
                  'border-primary shadow-lg': openTab === 3
                }"
              >
                <a
                  class="px-5 py-3 block
leading-normal cursor-pointer
"
                  @click="toggleTabs(3)"
                  @mouseover="toggleTabs(3)"
                >
                  <h3
                    class="text-lg md:text-3xl"
                    :class="{ 'text-primary-darkest': openTab === 3 }"
                  >
                    psychometric
                  </h3>
                  <p class="text-base md:text-lg">
                    Top performers are candidates who best fit with company
                    culture, so start now to make smart and memorable hiring
                    decisions
                  </p>
                </a>
              </li>
            </ul>
          </div>

          <div
            data-aos="fade-up-left"
            class="w-full md:w-6/12 tab-content tab-space flex items-center justify-center md:justify-end"
          >
            <div
              :class="{
                hidden: openTab !== 1,
                block: openTab === 1
              }"
            >
              <img :src="technicalTestImg" alt="technical image" />
            </div>
            <div
              :class="{
                hidden: openTab !== 2,
                block: openTab === 2
              }"
            >
              <img :src="englishTestImg" alt="english test" />
            </div>
            <div
              :class="{
                hidden: openTab !== 3,
                block: openTab === 3
              }"
            >
              <img
                :src="psychometricTestImg"
                alt="psychometric test
"
              />
            </div>
          </div>
        </div>
        <div
          class="flex items-center justify-center md:items-start md:justify-start"
        >
          <router-link
            to="/sign-up"
            class="bg-primary text-white px-6 py-4 rounded-full outline-none focus:outline-none mr-1 mb-1 text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
          >
            Start Free Trial
          </router-link>
        </div>
      </div>
    </section>

    <!-- brands -->
    <!-- <section class="bg-gray-100 relative z-1 pt-12 pb-12">
      <div class="container mx-auto">
        <p class="text-3xl leading-relaxed">
          Trusted by
        </p>

        <h2 class="font-gilroy-bold text-primary text-4.5xl leading-snug">
          Leading Brands
        </h2>

        <div class="justify-center flex flex-wrap mt-20">
          <div
            data-aos="zoom-in-down"
            v-for="(brand, i) in brands"
            :key="i"
            class="w-full md:w-1/5 px-4"
          >
            <a href="#" to="/">
              <div
                class="md:hover:-mt-4 relative flex flex-col min-w-0 b w-full mb-6 md:ease-linear md:transition-all md:duration-150"
              >
                <img
                  alt="brand.name"
                  class="mx-auto align-middle border-none w-28 h-28 object-contain flex-shrink-0"
                  :src="getURL(brand.logo)"
                />
              </div>
            </a>
          </div>
        </div>
      </div>
    </section> -->
    <!-- experience -->
    <section class="relative py-8 lg:py-20">
      <div class="container mx-auto">
        <!-- <h2 class="font-gilroy-bold text-primary text-4xl my-8">
          User Experience
        </h2> -->
        <div
          class="flex flex-col lg:flex-row text-center
"
        >
          <div
            data-aos="zoom-in-up"
            class="w-full md:w-4/12 mb-5 md:mb-0
text-lg flex flex-col items-center
"
          >
            <span
              class="flex items-center justify-center w-40 h-40 mb-5 bg-primary bg-opacity-10 rounded-full"
              ><TimeIcon
            /></span>

            <h3 class="text-2xl lg:text-5.75xl font-gilroy-bold">70%</h3>
            <h2 class="text-lg font-gilroy-bold">Faster time to hire</h2>
          </div>
          <div
            data-aos="zoom-in-up"
            class="w-full md:w-4/12 mb-5 md:mb-0
 text-lg flex flex-col items-center"
          >
            <span
              class="flex items-center justify-center w-40 h-40 mb-5 bg-primary bg-opacity-10 rounded-full"
              ><CostIcon
            /></span>
            <h3 class="text-2xl lg:text-5.75xl font-gilroy-bold">50%</h3>
            <h2 class="text-lg font-gilroy-bold">lower Cost per hire</h2>
          </div>

          <div
            data-aos="zoom-in-up"
            class="w-full md:w-4/12 mb-5 md:mb-0
 text-lg flex flex-col items-center"
          >
            <span
              class="flex items-center justify-center w-40 h-40 mb-5 bg-primary bg-opacity-10 rounded-full"
              ><PerformanceIcon
            /></span>

            <h3 class="text-2xl lg:text-5.75xl font-gilroy-bold">50%</h3>
            <h2 class="text-lg font-gilroy-bold">Better Performance</h2>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { ref } from "vue";
import Popup from "@/components/Popup.vue";

// icons
// import PlayIcon from "@/components/Icons/PlayIcon.vue";
import ScrollIcon from "@/components/Icons/ScrollIcon.vue";
import ArrowIcon from "@/components/Icons/ArrowIcon.vue";
import BulletIcon from "@/components/Icons/BulletIcon.vue";
import TimeIcon from "@/components/Icons/TimeIcon.vue";
import PerformanceIcon from "@/components/Icons/PerformanceIcon.vue";
import CostIcon from "@/components/Icons/CostIcon.vue";
import videoPoster from "@/assets/img/video-poster.png";

import introBg from "@/assets/img/intro-bg.png";
import aboutBg from "@/assets/img/about-bg.png";
import technicalTestImg from "@/assets/img/technical-test.png";
import englishTestImg from "@/assets/img/english-test.png";

import psychometricTestImg from "@/assets/img/psychometric-test.png";

import calculatorBg from "@/assets/img/calculator-bg.png";
import calculatorImg from "@/assets/img/calculator.png";

import { watchEffect } from "vue";
import { useMeta } from "vue-meta";

export default {
  setup() {
    // Add meta info
    // The object passed into useMeta is user configurable
    const { meta } = useMeta({
      title: "Pre-Hiring Tests –Automated CV Screening "
    });

    watchEffect(() => {
      meta.description =
        "Identify top talents easily with TalentFier pre-hiring tests. Save your time, and make your hiring process faster and more accurate.";
    });

    const openTab = ref(1);
    const vacancies = ref(0);

    const showResult = ref(false);
    const show = ref(false);

    const popupTriggers = ref({ buttonTrigger: false, timedTrigger: false });
    const TogglePopup = trigger => {
      popupTriggers.value[trigger] = !popupTriggers.value[trigger];
    };
    setTimeout(() => {
      popupTriggers.value.timedTrigger = true;
    }, 3000);

    return {
      show,
      showResult,
      vacancies,
      openTab,
      introBg,
      aboutBg,
      technicalTestImg,
      englishTestImg,
      psychometricTestImg,

      calculatorBg,
      calculatorImg,
      videoPoster,

      features: [
        {
          text:
            "Minimize no-show! by eliminating disqualified, and non-serious candidates easily."
        },
        {
          text:
            "Gain deeper insights about job applicants, and Invite only top talents for interviews."
        },
        {
          text:
            "Provide a positive experience to job candidates, and let your brand shine!"
        },

        {
          text:
            "“88% of organizations will use tests for hiring in next few years” - Harvard!"
        }
      ],
      brands: [
        {
          logo: "brands/1.png"
        },
        { logo: "brands/2.png" },
        { logo: "brands/3.png" },
        { logo: "brands/4.png" },
        { logo: "brands/5.png" }
      ],
      Popup,
      popupTriggers,
      TogglePopup
    };
  },

  components: {
    // PlayIcon,
    ArrowIcon,
    BulletIcon,
    TimeIcon,
    PerformanceIcon,
    CostIcon,
    ScrollIcon
  },
  methods: {
    toggleTabs(tabNumber) {
      this.openTab = tabNumber;
    },

    getURL(src) {
      //

      // console.log(path)
      return require(`@/assets/img/${src}`).default;
    },
    updateVacancies() {
      this.showResult = true;
    },
    showVideo() {
      this.show = true;
    }
  }
};
</script>
