<template>
  <nav
    id="header"
    class="relative z-40 w-full flex flex-wrap items-center justify-between px-2 navbar-expand-lg bg-white shadow"
  >
    <div
      class="container px-4 mx-auto flex flex-col md:flex-row items-center justify-between
"
    >
      <div
        class="relative flex justify-between w-full lg:w-1/4 lg:static lg:block lg:justify-start"
      >
        <router-link to="/">
          <a class="inline-block mr-4 py-2" href="/">
            <img src="../../assets/logo.png" alt="talentfier logo" />
          </a>
        </router-link>
        <button
          class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
          type="button"
          v-on:click="setNavbarOpen"
        >
          <i class="fas fa-bars"></i>
        </button>
      </div>
      <div
        class="lg:flex flex-grow items-center justify-center w-1/2"
        :class="[navbarOpen ? 'block' : 'hidden']"
        id="main-nav"
      >
        <ul class="flex flex-col lg:flex-row list-none">
          <li class="flex items-center">
            <router-link
              :class="[
                this.$route.path === '/' ? 'text-primary' : 'text-gray-500'
              ]"
              class="hover:text-primary px-3 py-2 flex items-center text-base"
              to="/"
            >
              <span class="ml-2">Home</span>
            </router-link>
          </li>

          <li class="flex items-center">
            <router-link
              :class="[
                this.$route.path === '/how-to-use'
                  ? 'text-primary'
                  : 'text-gray-500'
              ]"
              class="text-gray-500 hover:text-primary
 px-3 py-2 flex items-center text-base"
              to="/how-to-use"
            >
              <span class="ml-2">How to use </span>
            </router-link>
          </li>
          <li class="flex items-center">
            <router-link
              :class="[
                this.$route.path === '/pricing'
                  ? 'text-primary'
                  : 'text-gray-500'
              ]"
              class="text-gray-500 hover:text-primary
 px-3 py-2 flex items-center text-base"
              to="/pricing"
            >
              <span class="ml-2">Pricing </span>
            </router-link>
          </li>
          <li class="flex items-center">
            <router-link
              :class="[
                this.$route.path === '/faq' ? 'text-primary' : 'text-gray-500'
              ]"
              class="text-gray-500 hover:text-primary
 px-3 py-2 flex items-center text-base"
              to="/faq/"
            >
              <span class="ml-2">FAQ </span>
            </router-link>
          </li>

          <li class="flex items-center">
            <index-dropdown />
          </li>
        </ul>
      </div>
      <div
        class="lg:flex flex-grow items-center w-1/2 lg:w-1/4"
        :class="[navbarOpen ? 'block' : 'hidden']"
        id="main-nav"
      >
        <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">
          <li class="flex items-center">
            <router-link
              to="/sign-up"
              class="bg-primary whitespace-nowrap rounded-full text-white text-base px-4 py-2 shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 mb-3 mt-3"
              type="button"
            >
              Get Started
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import IndexDropdown from "@/components/Dropdowns/IndexDropdown.vue";

export default {
  data() {
    return {
      navbarOpen: false
    };
  },
  methods: {
    setNavbarOpen: function() {
      this.navbarOpen = !this.navbarOpen;
    }
  },
  components: {
    IndexDropdown
  }
};
</script>
