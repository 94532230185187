<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="90.402"
    height="90.402"
    viewBox="0 0 90.402 90.402"
  >
    <g id="Group_3624" data-name="Group 3624" transform="translate(-3.5 -3.5)">
      <path
        id="Path_10636"
        data-name="Path 10636"
        d="M49.212,21.8A27.412,27.412,0,1,0,76.625,49.212,27.443,27.443,0,0,0,49.212,21.8Zm0,51.908a24.5,24.5,0,1,1,24.5-24.5A24.518,24.518,0,0,1,49.212,73.708Z"
        transform="translate(-0.511 -0.511)"
        fill="#259dc6"
      />
      <path
        id="Path_10637"
        data-name="Path 10637"
        d="M31.488,48.5h-2.43a1.458,1.458,0,1,0,0,2.916h2.43a1.5,1.5,0,0,0,1.458-1.458A1.432,1.432,0,0,0,31.488,48.5Z"
        transform="translate(-0.673 -1.257)"
        fill="#259dc6"
      />
      <path
        id="Path_10638"
        data-name="Path 10638"
        d="M66.9,49.958a1.5,1.5,0,0,0,1.458,1.458h2.43a1.458,1.458,0,0,0,0-2.916h-2.43A1.432,1.432,0,0,0,66.9,49.958Z"
        transform="translate(-1.771 -1.257)"
        fill="#259dc6"
      />
      <path
        id="Path_10639"
        data-name="Path 10639"
        d="M36.229,34.087a1.443,1.443,0,1,0-2.041,2.041l1.75,1.75a1.665,1.665,0,0,0,2.139,0,1.41,1.41,0,0,0,0-2.041Z"
        transform="translate(-0.845 -0.842)"
        fill="#259dc6"
      />
      <path
        id="Path_10640"
        data-name="Path 10640"
        d="M64.029,61.887a1.443,1.443,0,1,0-2.041,2.041l1.75,1.75a1.665,1.665,0,0,0,2.139,0,1.41,1.41,0,0,0,0-2.041Z"
        transform="translate(-1.621 -1.619)"
        fill="#259dc6"
      />
      <path
        id="Path_10641"
        data-name="Path 10641"
        d="M35.837,61.887l-1.75,1.75a1.41,1.41,0,0,0,0,2.041,1.665,1.665,0,0,0,2.139,0l1.75-1.75a1.41,1.41,0,0,0,0-2.041A1.526,1.526,0,0,0,35.837,61.887Z"
        transform="translate(-0.842 -1.619)"
        fill="#259dc6"
      />
      <path
        id="Path_10642"
        data-name="Path 10642"
        d="M63.637,34.088l-1.75,1.75a1.41,1.41,0,0,0,0,2.041,1.665,1.665,0,0,0,2.139,0l1.75-1.75a1.41,1.41,0,0,0,0-2.041A1.626,1.626,0,0,0,63.637,34.088Z"
        transform="translate(-1.619 -0.843)"
        fill="#259dc6"
      />
      <path
        id="Path_10643"
        data-name="Path 10643"
        d="M49.958,32.946a1.5,1.5,0,0,0,1.458-1.458v-2.43a1.458,1.458,0,1,0-2.916,0v2.43A1.432,1.432,0,0,0,49.958,32.946Z"
        transform="translate(-1.257 -0.673)"
        fill="#259dc6"
      />
      <path
        id="Path_10644"
        data-name="Path 10644"
        d="M92.444,38.883H85.737a37.864,37.864,0,0,0-3.888-9.429l4.763-4.763A1.462,1.462,0,0,0,87,23.622a2.038,2.038,0,0,0-.389-1.069L74.753,10.888a1.409,1.409,0,0,0-2.041,0l-4.763,4.763a36.412,36.412,0,0,0-9.429-3.888v-6.8A1.5,1.5,0,0,0,57.061,3.5H40.341a1.5,1.5,0,0,0-1.458,1.458v6.707a37.864,37.864,0,0,0-9.429,3.888l-4.763-4.763a1.409,1.409,0,0,0-2.041,0L10.888,22.65a1.462,1.462,0,0,0-.389,1.069,2.038,2.038,0,0,0,.389,1.069l4.763,4.763a36.412,36.412,0,0,0-3.888,9.429h-6.8A1.5,1.5,0,0,0,3.5,40.439v16.72a1.5,1.5,0,0,0,1.458,1.458h6.707a37.864,37.864,0,0,0,3.888,9.429l-4.763,4.763a1.409,1.409,0,0,0,0,2.041L22.65,86.709a1.41,1.41,0,0,0,2.041,0l4.763-4.763a36.411,36.411,0,0,0,9.429,3.888v6.61A1.5,1.5,0,0,0,40.341,93.9h16.72a1.5,1.5,0,0,0,1.458-1.458V85.737a37.864,37.864,0,0,0,9.429-3.888l4.763,4.763a1.409,1.409,0,0,0,2.041,0L86.612,74.753a1.409,1.409,0,0,0,0-2.041l-4.763-4.763a36.412,36.412,0,0,0,3.888-9.429h6.707A1.5,1.5,0,0,0,93.9,57.061V40.341A1.5,1.5,0,0,0,92.444,38.883ZM90.986,55.6H84.57a1.548,1.548,0,0,0-1.458,1.166,35.444,35.444,0,0,1-4.374,10.693,1.471,1.471,0,0,0,.194,1.847L83.5,73.878,73.781,83.6,69.212,79.03a1.471,1.471,0,0,0-1.847-.194A35.444,35.444,0,0,1,56.672,83.21a1.548,1.548,0,0,0-1.166,1.458v6.416H41.8V84.668a1.548,1.548,0,0,0-1.166-1.458A35.444,35.444,0,0,1,29.94,78.835a1.471,1.471,0,0,0-1.847.194L23.525,83.6,13.8,73.878l4.569-4.569a1.471,1.471,0,0,0,.194-1.847,35.444,35.444,0,0,1-4.374-10.693A1.548,1.548,0,0,0,12.735,55.6H6.416V41.8h6.416a1.548,1.548,0,0,0,1.458-1.166A35.444,35.444,0,0,1,18.664,29.94a1.471,1.471,0,0,0-.194-1.847L13.9,23.525,23.622,13.8l4.569,4.569a1.471,1.471,0,0,0,1.847.194A35.444,35.444,0,0,1,40.73,14.193,1.548,1.548,0,0,0,41.9,12.735V6.416H55.7v6.416a1.548,1.548,0,0,0,1.166,1.458,35.444,35.444,0,0,1,10.693,4.374,1.471,1.471,0,0,0,1.847-.194L73.975,13.9,83.7,23.622l-4.569,4.569a1.471,1.471,0,0,0-.194,1.847A35.444,35.444,0,0,1,83.307,40.73,1.548,1.548,0,0,0,84.765,41.9h6.416V55.6Z"
        fill="#259dc6"
      />
      <path
        id="Path_10645"
        data-name="Path 10645"
        d="M57.795,30.2a1.5,1.5,0,0,0-1.944.778L50.6,43.709a3.988,3.988,0,0,0-1.069-.1,5.761,5.761,0,1,0,3.791,1.361l5.249-12.831A1.5,1.5,0,0,0,57.795,30.2ZM49.532,52.36a2.925,2.925,0,0,1-2.916-2.916,2.916,2.916,0,0,1,5.832,0A2.925,2.925,0,0,1,49.532,52.36Z"
        transform="translate(-1.123 -0.743)"
        fill="#259dc6"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>
