<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="4.822"
    height="8.143"
    viewBox="0 0 4.822 8.143"
  >
    <g id="arrow-down-circle" transform="translate(-16.117 18.235) rotate(-90)">
      <path
        id="Path_641"
        data-name="Path 641"
        d="M15.022,13.5l-3.011,3.011L9,13.5"
        transform="translate(2.152 3.678)"
        fill="none"
        stroke="#fff"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>
