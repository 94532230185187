<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8.113"
    height="6.093"
    viewBox="0 0 8.113 6.093"
  >
    <path
      id="Path_632"
      data-name="Path 632"
      d="M410.836,370.3l3.434,4.849a.621.621,0,0,0,1.014,0l3.434-4.849a.622.622,0,0,0-.507-.981h-6.867A.622.622,0,0,0,410.836,370.3Z"
      transform="translate(-410.72 -369.324)"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>
