<template>
  <div>
    <Breadcrumb :crumbs="crumbs" @selected="selected">
      <div class="flex items-center justify-center">
        <div class="relative">
          <h2
            class="relative text-3xl md:text-7xl font-gilroy-bold text-center z-50"
          >
            About Us
          </h2>
          <AboutIcon
            class="absolute -top-1/2 -left-1/3 z-0
"
          />
        </div>
      </div>
    </Breadcrumb>

    <main>
      <div
        class="container relative py-16 px-5 lg:px-12 flex bg-cover rounded-b-3xl bg-top bg-no-repeat flex-col content-center items-center justify-center"
        :style="{ backgroundImage: 'url(' + aboutBg + ')' }"
      >
        <div class="flex flex-col lg:flex-row justify-between items-center ">
          <div class="w-full md:w-6/12 flex items-center">
            <SiteIcon class="mr-5" />
            <h1 class="text-primary text-lg lg:text-3.5xl lg:leading-relaxed">
              Identifying the right talents and top performers is now easier!
            </h1>
          </div>
          <div
            class="w-full md:w-4/12 bg-white shadow-lg rounded-lg p-5 text-base font-gilroy-regular space-y-2"
          >
            <p>
              One of the top challenges businesses struggle with nowadays is
              finding the right candidates, that’s why Talentfier was
              established.
            </p>
            <p>
              TalentFier is a mix of two words that explain our mission Talent &
              Identifier, which turned into Talentfier.
            </p>
            <p>
              Our mission is empowering organizations to easily identify talents
              and make hiring easier and faster.
            </p>
          </div>
        </div>
      </div>
      <div
        class="container py-12 text-base font-gilroy-regular text-gray-500 flex flex-col leading-relaxed"
      >
        <div class="mb-12">
          <h3 class="text-1.5xl text-primary-darkest my-6 font-gilroy-bold">
            Why pre-hiring assessments?
          </h3>
          <p>As per recent studies:</p>
          <ul class="list-disc list-inside w-full lg:w-1/2">
            <li>
              For every vacancy, recruiter consumes around 30 hour to identify
              the top pre-assessed shortlist.
            </li>
            <li>
              80% of the applicants are usually irrelevant to the announced job!
            </li>
            <li>
              The average cost per hire expected to be 4K EGP for entry level,
              and can reach to 20K EGP for higher levels.
            </li>
          </ul>
        </div>
        <div>
          <h3 class="text-1.5xl text-primary-darkest my-6 font-gilroy-bold">
            How can pre-hiring assessments be priceless for companies?
          </h3>
          <p class="text-primary font-gilroy-medium">For Recruiters:</p>
          <ul class="list-disc list-inside w-full lg:w-1/2">
            <li>
              Saving time and effort by automating screening and evaluation
              process.
            </li>
            <li>
              Minimize “no show” problem by inviting only top serious and
              qualified candidates for interviews.
            </li>
            <li>
              View insightful data about applied candidates in one page even
              high level or in details
            </li>
          </ul>
          <p class="text-primary font-gilroy-medium mt-12">For HR managers:</p>
          <ul class="list-disc list-inside w-full lg:w-1/2">
            <li>
              Providing amazing recruitment experience for job seekers, which
              affects company brand positively.
            </li>
            <li>
              Minimizing the non-billable hours consumed in hiring.
            </li>
            <li>
              View insightful data about applied candidates in one page even
              high level or in detailsCreating a data-driven organization, and
              Increase company diversity & knowledge and avoid biases in hiring.
            </li>
            <li>
              Affordable and easy to use professional tool on all hiring levels
              not only for top levels.
            </li>
            <li>
              In general, Assessments are a powerful tool that empower decision
              makers to make confident decisions in hiring, promotion and
              termination.
            </li>
          </ul>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import AboutIcon from "@/components/Icons/AboutIcon.vue";
import SiteIcon from "@/components/Icons/SiteIcon.vue";

import aboutBg from "@/assets/img/about-page.png";

import { watchEffect } from "vue";
import { useMeta } from "vue-meta";

export default {
  setup() {
    // Add meta info
    // The object passed into useMeta is user configurable
    const { meta } = useMeta({
      title: "About Us"
    });

    watchEffect(() => {
      meta.description =
        "TalentFier is a mix of two words Talent & Identifier, which turned into TalentFier. Therefore, we are here to empower HR teams to easily identify talents.";
    });
  },
  data() {
    return {
      crumbs: ["Home", "About Us"],
      aboutBg
    };
  },

  components: { Breadcrumb, AboutIcon, SiteIcon },
  methods: {
    selected(crumb) {
      console.log(crumb);
    },
    getURL(src) {
      // // console.log(path) return
      require(`@/assets/img/${src}`).default;
    }
  }
};
</script>
