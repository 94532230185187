<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="51.515"
    height="78.346"
    viewBox="0 0 51.515 78.346"
  >
    <g id="Group_3623" data-name="Group 3623" transform="translate(-26 -13.5)">
      <path
        id="Path_10631"
        data-name="Path 10631"
        d="M48.323,67.161a.645.645,0,0,1-.429-.107,1.6,1.6,0,0,1-1.181-1.932l6.869-25.757a1.6,1.6,0,1,1,3.112.751L49.826,65.874A1.488,1.488,0,0,1,48.323,67.161Zm16.85-5.581V58.146a5.826,5.826,0,0,0-5.9-5.581,5.754,5.754,0,0,0-5.9,5.581v3.434a5.826,5.826,0,0,0,5.9,5.581A5.754,5.754,0,0,0,65.173,61.581Zm-3.22-3.327v3.434a2.705,2.705,0,0,1-5.366,0V58.254a2.705,2.705,0,0,1,5.366,0ZM50.148,47.092V43.658a5.826,5.826,0,0,0-5.9-5.581,5.754,5.754,0,0,0-5.9,5.581v3.434a5.826,5.826,0,0,0,5.9,5.581A5.754,5.754,0,0,0,50.148,47.092Zm-3.22-3.327V47.2a2.705,2.705,0,0,1-5.366,0V43.765a2.705,2.705,0,0,1,5.366,0ZM77.515,31.208h0V90.236a1.619,1.619,0,0,1-.966,1.5,1.361,1.361,0,0,1-.644.107,1.378,1.378,0,0,1-1.073-.429l-3.756-3.327-3.756,3.327a1.632,1.632,0,0,1-2.146,0l-3.756-3.327L57.66,91.416a1.632,1.632,0,0,1-2.146,0l-3.756-3.327L48,91.416a1.632,1.632,0,0,1-2.146,0L42.1,88.089l-3.756,3.327a1.632,1.632,0,0,1-2.146,0l-3.756-3.327-3.756,3.327a1.458,1.458,0,0,1-1.717.215,1.385,1.385,0,0,1-.966-1.4V15.11a1.651,1.651,0,0,1,1.61-1.61H60.129c.107,0,.107,0,.215.107h.107c.107,0,.107.107.215.107h.107c.107.107.215.107.215.215l8.049,8.049c4.615,4.615,6.654,6.654,7.62,7.727a1.662,1.662,0,0,1,.859,1.181v.322Zm-16.1-12.235V29.6H72.042ZM74.3,86.694V32.818H59.807a1.651,1.651,0,0,1-1.61-1.61V16.72H29.22V86.694l2.146-1.932a1.556,1.556,0,0,1,2.146,0l3.756,3.327,3.756-3.327a1.632,1.632,0,0,1,2.146,0l3.756,3.327,3.756-3.327a1.632,1.632,0,0,1,2.146,0l3.756,3.327,3.756-3.327a1.632,1.632,0,0,1,2.146,0l3.756,3.327L70,84.762a1.632,1.632,0,0,1,2.146,0ZM67.856,75.747h-32.2a1.61,1.61,0,1,0,0,3.22h32.2a1.61,1.61,0,1,0,0-3.22Z"
        fill="#259dc6"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>
