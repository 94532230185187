<template>
  <footer
    class="container mx-auto
relative"
  >
    <div
      class="py-8 px-6 bg-no-repeat text-white rounded-t-2xl "
      :style="{
        backgroundImage: 'url(' + footerBg + ')',
        backgroundColor: '#259DC6',
        backgroundPosition: '100% 0'
      }"
    >
      <div class="flex flex-col md:flex-row text-center lg:text-left">
        <div class="w-full md:w-3/12 mb-5 lg:mb-0">
          <img src="../../assets/img/logo-white@2x.png" alt="talentfier logo" />
        </div>
        <div class="w-full md:w-3/12 ml-0 lg:ml-12 mb-5 lg:mb-0">
          <h2 class="block text-primary-darkest text-sm mb-2">
            Company
          </h2>
          <ul class="list-unstyled">
            <li>
              <router-link class="block pb-2 text-sm" to="/about-us">
                About Us
              </router-link>
            </li>
            <li>
              <router-link class="block pb-2 text-sm" to="/careers">
                Careers
              </router-link>
            </li>
            <li>
              <router-link class="block pb-2 text-sm" to="/contact-us">
                Contact us
              </router-link>
            </li>
            <li>
              <router-link class="block pb-2 text-sm" to="/pricing">
                Pricing
              </router-link>
            </li>
          </ul>
        </div>
        <div class="w-full md:w-3/12 ml-0 lg:ml-12 mb-5 lg:mb-0 pl-5">
          <h2 class="block text-primary-darkest text-sm mb-2">
            Resources
          </h2>

          <ul class="list-unstyled">
            <li>
              <a
                class="block pb-2 text-sm"
                href="https://blog.talentfier.com/ "
                target="_blank"
              >
                Blog
              </a>
            </li>
            <!-- <li>
              <a class="block pb-2 text-sm" href="/">
                Help Center
              </a>
            </li> -->
            <li>
              <router-link class="block pb-2 text-sm" to="/faq/">
                FAQ
              </router-link>
            </li>
          </ul>
        </div>

        <div class="w-full md:w-3/12 ml-0 lg:ml-12 mb-5 lg:mb-0">
          <h2 class="block text-primary-darkest text-sm mb-2">
            Social
          </h2>

          <div class="mt-6 lg:mb-0 mb-6">
            <a
              href="https://twitter.com/TalentFier
"
              target="_blank"
              class=" mr-2"
            >
              <i class="fab fa-twitter"></i>
            </a>
            <a
              href="https://www.facebook.com/TalentFier"
              target="_blank"
              class=" mr-2"
            >
              <i class="fab fa-facebook"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/talentfier
"
              target="_blank"
              class=" mr-2"
            >
              <i class="fab fa-linkedin"></i>
            </a>
            <a
              href="https://www.youtube.com/channel/UCf9YePnAxsTE3O6vDvhwtJA

"
              target="_blank"
              class=" mr-2"
            >
              <i class="fab fa-youtube"></i>
            </a>
          </div>
        </div>
      </div>
      <div
        class="flex flex-wrap items-center md:justify-between justify-center"
      >
        <div class="w-full md:w-4/12 px-4 mx-auto text-center">
          <div class="text-sm py-1">
            Copyright © {{ date }} TalentFier, All Rights Reserved .
          </div>
        </div>
      </div>
    </div>
    <a href="#header" @click.prevent="toTop" class="absolute -top-5 right-5"
      ><ToTop class="shadow-lg rounded-full"
    /></a>
  </footer>
</template>
<script>
import footerBg from "@/assets/img/footer-bg.png";
import ToTop from "@/components/Icons/ToTop.vue";
export default {
  data() {
    return {
      footerBg,
      date: new Date().getFullYear(),
      scTimer: 0,
      scY: 0
    };
  },
  components: {
    ToTop
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }
};
</script>
