<template>
  <div>
    <Breadcrumb :crumbs="crumbs" @selected="selected">
      <div class="flex items-center justify-center">
        <div class="relative">
          <h2
            class="relative text-3xl md:text-7xl
 font-gilroy-bold text-center z-50"
          >
            FAQ
          </h2>
          <FaqIcon
            class="absolute -top-1/2 -left-1/3 z-0
"
          />
        </div>
      </div>
    </Breadcrumb>

    <main>
      <div
        class="container relative py-16 flex flex-col content-center items-center justify-center"
      >
        <h1
          class="text-primary-darkest text-xl lg:text-4.5xl font-gilroy-bold my-5 lg:my-20"
        >
          frequently asked questions
        </h1>
        <div class="accordions w-full">
          <dl v-for="(faq, index) in faqs" :key="index" class="mb-5">
            <button
              :class="
                faq.open ? 'text-primary' : 'text-gray-500 text-opacity-40'
              "
              class="flex justify-between items-center bg-white border-2 border-gray-50 rounded-2xl py-3 px-8 w-full focus:outline-none"
              @click="faq.open = !faq.open"
            >
              <p
                class="text-base md:text-1.5xl
"
              >
                {{ faq.question }}
              </p>
              <ArrowUpIcon
                v-if="faq.open"
                class="text-gray-500 text-opacity-40"
              />
              <ArrowDownIcon v-else class="text-gray-500 text-opacity-40" />
            </button>
            <p
              v-if="faq.open"
              class="p-5 bg-white border-2 border-gray-50
shadow-lg rounded-2xl mt-5 font-gilroy-regular text-lg text-gray-500"
              v-html="faq.answer"
            ></p>
          </dl>
        </div>
        <div class="flex flex-col items-center text-lg text-center">
          <p>
            Still have a questions?<br />
            If you cannot find answer to your question in our FAQ, you can
            always<br />
            contact us. We Will answer you shortly.
          </p>
          <a
            href="/contact-us/"
            target="_blank"
            class="flex items-center font-gilroy-bold text-primary  mr-1 my-6 text-base ease-linear transition-all duration-150"
          >
            Contact Us
            <ArrowIcon class="ml-5 text-primary w-5" />
          </a>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import FaqIcon from "@/components/Icons/FaqIcon.vue";
import ArrowUpIcon from "@/components/Icons/ArrowUpIcon.vue";
import ArrowDownIcon from "@/components/Icons/ArrowDownIcon.vue";
import ArrowIcon from "@/components/Icons/ArrowIcon.vue";
import { watchEffect } from "vue";
import { useMeta } from "vue-meta";

export default {
  setup() {
    // Add meta info
    // The object passed into useMeta is user configurable
    const { meta } = useMeta({
      title: "Frequently Asked Questions (FAQ)"
    });

    watchEffect(() => {
      meta.description =
        "Browse our knowledge center and the most common questions we received to help you in using pre-hiring tests.";
    });
  },
  data() {
    return {
      crumbs: ["Home", "FAQ"],
      open: false,
      faqs: [
        {
          question: "Why pre-hiring Tests becomes very important?",
          answer:
            "Because smart hiring decision takes companies to the next levels, and as per Harvard study “88% of organizations with +100 employees will use tests for hiring in next few years”"
        },
        {
          question: "What is pre-hiring Tests?",
          answer:
            "Pre hiring / Employment tests are exams given to a job applicant by a potential employer before the possibility of hiring, these tests identifies the real talents of applicants, regardless the information wrote in their resumes, it can include language proficiency tests, technical tests, cognitive ability test, personality …etc."
        },
        {
          question: " What is the difference between assessment and test?",
          answer:
            "Assessment can contain multiple tests so as example you can create “software engineer assessment” which contains technical, English and personality tests."
        },
        {
          question: "How pre-hiring tests can help me as a recruiter?",
          answer:
            "Pre-hiring / Employment tests can reduce the wasted time in resume screening by 80%, and enhance the “Time to hire” by 70%. All you need to do is just three steps; create assessment and select desired tests, invite applicants, pick the top scored candidates for hiring or final interviews, for more details check this <a class='text-primary' href='/how-to-use'>link</a>"
        },
        {
          question: "What is pre-qualifying questions?",
          answer:
            "It is a special feature we provide in <b> TalentFier </b> to save recruiters time and money! We ask the applicants first to answer specific questions; if they passed it correctly, they can be move to the next step and take the assessment."
        },
        {
          question: "How much does it cost?",
          answer:
            "We are always keen to keep it affordable and best value for money, so pick the plan that best fit with your needs monthly or yearly from this <a class='text-primary' href='/pricing'>link</a>"
        },
        {
          question: "How much time will I save?",
          answer:
            "It depends on your recruitment channels, Employer brand, and the quality of job description, announced salary and benefits, but in general, you can save from 30% to 70% per vacancy."
        },
        {
          question: "Will you charge me by assessment link click / generation?",
          answer:
            "This a very strong competitive advantage that differentiate TalentFier. Most assessment firms will charge your by report or per test taker, but we know that wouldn’t be the best choice for our partners in screening phase, so fees is per created assessment"
        },
        {
          question:
            "As a recruiter, can I access your DB of pre-evaluated candidates?",
          answer:
            "We are working to make this service available soon, but let’s know if you are interested in such service and <a href='/contact-us' class='text-primary'>contact us</a> from here"
        },
        {
          question:
            "How Can I benefit as a (SME- Subject Matter Expert) from talentFier?",
          answer:
            "We are always updating and upgrading our tests, so if you ready to create tests, that will be win-win situation, and your will earn money as much as your test used by employers."
        },
        {
          question: "Can I integrate TalentFier with my ATS?",
          answer:
            "We are planning to make this available soon, but we will make it based on integration requests we receive so <a href='/contact-us' class='text-primary'>let us know </a> your needs and currently used ATS"
        }
      ]
    };
  },

  components: { Breadcrumb, FaqIcon, ArrowUpIcon, ArrowDownIcon, ArrowIcon },
  methods: {
    selected(crumb) {
      console.log(crumb);
    }
  }
};
</script>
