<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.293"
    height="13.091"
    viewBox="0 0 13.293 13.091"
  >
    <path
      id="Path_735"
      data-name="Path 735"
      d="M511.726,325.224l3.71-9.971a1.583,1.583,0,0,1,1.469-1.036h.159a1.584,1.584,0,0,1,1.469,1.036l3.71,9.971"
      transform="translate(326.51 -510.44) rotate(90)"
      fill="none"
      stroke="#15509d"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {};
</script>
