<template>
  <div class="min-h-screen">
    <Breadcrumb :crumbs="crumbs" @selected="selected">
      <div class="flex items-center justify-center">
        <div class="relative">
          <h2
            class="relative text-3xl md:text-7xl font-gilroy-bold text-center z-50"
          >
            Careers
          </h2>
          <AboutIcon
            class="absolute -top-1/2 -left-1/3 z-0
"
          />
        </div>
      </div>
    </Breadcrumb>
    <div class="container flex justify-between z-50">
      <div class="w-full">
        <div class="pt-0 lg:pt-12">
          <p>
            We are always looking for top talents to join our team. If you are
            interested in talent assessment industry, and would like to add
            value to our clients, please sent us your CV on
            <a class="text-primary" href="mailto:info@talentfier.com"
              >info@talentfier.com </a
            >.
          </p>
          <h4
            class="text-primary font-gilroy-bold text-lg lg:text-4.5xl leading-snug my-5"
          >
            TalentFier<span class="text-primary-darkest"> Core Values</span>
          </h4>
          <div
            v-for="(value, i) in values"
            :key="i"
            class="my-2 md:my-3 flex items-center "
          >
            <BulletIcon class="mr-5 flex-shrink-0" />
            <p class="text-lg">
              {{ value.text }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BulletIcon from "@/components/Icons/BulletIcon.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import AboutIcon from "@/components/Icons/AboutIcon.vue";

import { watchEffect } from "vue";
import { useMeta } from "vue-meta";
export default {
  setup() {
    // Add meta info
    // The object passed into useMeta is user configurable
    const { meta } = useMeta({
      title: "Careers"
    });

    watchEffect(() => {
      meta.description = "TalentFier App Careers";
    });
    return {
      values: [
        {
          text: "Commitment"
        },
        {
          text: "Integrity"
        },
        {
          text: "Continuous Improvement"
        },
        {
          text: "Trustworthy"
        }
      ]
    };
  },
  components: {
    BulletIcon,
    Breadcrumb,
    AboutIcon
  }
};
</script>
