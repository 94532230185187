<template>
  <svg
  xmlns="http://www.w3.org/2000/svg"
  width="97.293"
  height="93.05"
  viewBox="0 0 97.293 93.05"
>
  <g id="Group_3585" data-name="Group 3585" transform="translate(3078 11803.548)">
    <path id="Path_10566" data-name="Path 10566" d="M660.89,383.334a2.184,2.184,0,0,1,2.164-2.164h8.656a2.164,2.164,0,1,1,0,4.328h-8.656A2.17,2.17,0,0,1,660.89,383.334Z" transform="translate(-3714.005 -12141.438)" fill="#31418f"/>
    <path id="Path_10567" data-name="Path 10567" d="M660.89,373.334a2.184,2.184,0,0,1,2.164-2.164h43.279a2.164,2.164,0,1,1,0,4.328H663.054A2.17,2.17,0,0,1,660.89,373.334Z" transform="translate(-3714.005 -12142.259)" fill="#f7c26f"/>
    <path id="Path_10568" data-name="Path 10568" d="M660.89,363.334a2.184,2.184,0,0,1,2.164-2.164h43.279a2.164,2.164,0,1,1,0,4.328H663.054A2.17,2.17,0,0,1,660.89,363.334Z" transform="translate(-3714.005 -12143.078)" fill="#259dc6"/>
    <path id="Path_10569" data-name="Path 10569" d="M734.727,379.688a5.849,5.849,0,0,0-1.948-2.6L721.2,368v-9.521a6.684,6.684,0,0,0-4.328-6.167v-4.652a6.511,6.511,0,0,0-6.492-6.492H662.776a6.511,6.511,0,0,0-6.492,6.492v4.652a6.488,6.488,0,0,0-4.328,6.167V368l-11.577,9.089a6.375,6.375,0,0,0-1.948,2.489v.108a7.276,7.276,0,0,0-.541,2.489v45.551a6.882,6.882,0,0,0,1.839,4.544,6.957,6.957,0,0,0,4.652,1.948h84.394a6.83,6.83,0,0,0,4.544-1.839,6.34,6.34,0,0,0,1.839-4.469V382.177A4.7,4.7,0,0,0,734.727,379.688ZM721.2,373.413l8.656,6.816-8.656,8.548Zm-60.591-25.751a2.184,2.184,0,0,1,2.164-2.164h47.607a2.17,2.17,0,0,1,2.164,2.164v4.328H660.612Zm-4.328,10.82a2.184,2.184,0,0,1,2.164-2.164H714.71a2.17,2.17,0,0,1,2.164,2.164V393l-.216.108-10.171,9.954-10.17-9.954-5.194-5.085a6.465,6.465,0,0,0-9.089,0l-5.193,5.085-10.171,9.954L656.5,393.1l-.216-.108Zm-4.328,14.931v15.364l-8.656-8.439Zm-9.738,53.45V385.315l21.315,20.774Zm3.138,3.03,39.708-38.735a2.1,2.1,0,0,1,2.965-.065l.065.065L727.8,429.892Zm85.584-3.03-21.315-20.774,21.315-20.774Z" transform="translate(-3715.89 -12144.718)" fill="#259dc6"/>
  </g>
</svg>

</template>

<script>
export default {};
</script>
