<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.977"
    height="9.488"
    viewBox="0 0 16.977 9.488"
  >
    <g id="arrow-down-circle" transform="translate(15.563 8.074) rotate(180)">
      <path
        id="Path_641"
        data-name="Path 641"
        d="M14.148,0,7.074,7.074,0,0"
        transform="translate(0 0)"
        fill="none"
        stroke="rgba(17,20,22,0.4)"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>
