<template>
  <div class="overflow-x-hidden">
    <metainfo>
      <template v-slot:title="{ content }">{{ content }} - TalentFier</template>
      <template v-slot:discription="{ metainfo }">{{
        metainfo.description
      }}</template>
    </metainfo>

    <navbar />
    <router-view />
    <footer-component />

    <a
      href="#"
      @click.prevent="toggleSubscribeModal()"
      class="fixed bottom-20 left-0 bg-white z-50 p-2 rounded-r-xl shadow-lg"
    >
      <SubscribeIcon class="w-12" />
    </a>
    <div
      v-if="showSubscribe"
      class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-start items-end flex"
    >
      <div
        v-click-outside="onClickOutside"
        class="relative w-full md:w-1/4 lg:w-1/4 left-0 bottom-0 md:left-2 md:bottom-2
"
      >
        <!--content-->
        <div
          class="border-0 rounded-lg shadow-lg relative mt-5 flex flex-col w-full bg-white outline-none focus:outline-none"
        >
          <form
            :class="{ formSubmitted: formSubmitted }"
            id="contact-form"
            v-if="!isLoading && !formSubmitted"
          >
            <!--header-->
            <div
              :style="{
                backgroundImage: 'url(' + subscribeBg + ')'
              }"
              class="relative flex items-center justify-center h-40 bg-contain bg-no-repeat rounded-t"
            >
              <SubscribtionIcon />
              <button
                class="absolute right-1 top-1 outline-none z-50 focus:outline-none cursor-pointer"
                v-on:click="toggleSubscribeModal()"
              >
                <MinusIcon />
              </button>
            </div>
            <!--body-->
            <div
              class="relative px-6 flex-col items-center justify-center text-center"
            >
              <h1 class="text-gray-500 text-3.5xl font-gilroy-bold">
                Subscribe
              </h1>
              <p class="text-sm text-gray-500 text-opacity-40 mt-2">
                enjoy our special offers and stay updated with new features
              </p>

              <div class="relative w-full mb-1">
                <input
                  type="text"
                  v-model="v$.subscribe.first_name.$model"
                  class="border-1.5 border-gray-50 px-5 py-2 placeholder-gray-500 placeholder-opacity-40 text-gray-500 bg-white rounded-full text-lg focus:outline-none w-full ease-linear transition-all duration-150"
                  placeholder="First Name



"
                />
                <div
                  v-for="(error, index) of v$.subscribe.first_name.$errors"
                  :key="index"
                >
                  <div class="text-red-500">{{ error.$message }}</div>
                </div>
              </div>
              <div class="relative w-full mb-1">
                <input
                  type="text"
                  v-model="v$.subscribe.last_name.$model"
                  class="border-1.5 border-gray-50 px-5 py-2 placeholder-gray-500 placeholder-opacity-40 text-gray-500 bg-white rounded-full text-lg focus:outline-none w-full ease-linear transition-all duration-150"
                  placeholder="Last Name



"
                />
                <div
                  v-for="(error, index) of v$.subscribe.last_name.$errors"
                  :key="index"
                >
                  <div class="text-red-500">{{ error.$message }}</div>
                </div>
              </div>
              <div class="relative w-full mb-1">
                <input
                  type="email"
                  v-model="v$.subscribe.email.$model"
                  class="border-1.5 border-gray-50 px-5 py-2 placeholder-gray-500 placeholder-opacity-40 text-gray-500 bg-white rounded-full text-lg focus:outline-none w-full ease-linear transition-all duration-150"
                  placeholder="Email

"
                />
                <div
                  v-for="(error, index) of v$.subscribe.email.$errors"
                  :key="index"
                >
                  <div class="text-red-500">{{ error.$message }}</div>
                </div>
              </div>
            </div>
            <!--footer-->
            <div class="flex items-center justify-center rounded-b">
              <div class="w-full text-center my-5 mx-12">
                <input
                  :disabled="isLoading"
                  class="bg-primary text-white active:bg-opacity-50 text-base px-6 py-3
rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1
w-full ease-linear transition-all duration-150 cursor-pointer"
                  @click.prevent="submit"
                  type="submit"
                  value="Subscribe
"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      v-if="showSubscribe"
      class="opacity-25 fixed inset-0 z-40 bg-black"
    ></div>
    <div
      v-if="showConfirmation"
      class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
    >
      <div
        class="relative w-full md:w-1/3 lg:w-1/4
"
      >
        <!--content-->
        <div
          class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
        >
          <!--header-->
          <div
            :style="{
              backgroundImage: 'url(' + subscribeBg + ')'
            }"
            class="relative flex items-center justify-center h-40 bg-contain bg-no-repeat rounded-t"
          >
            <SentIcon />
            <a
              class="absolute right-1 top-1 outline-none z-50 focus:outline-none cursor-pointer"
              @click.prevent="showConfirmation = false"
            >
              <MinusIcon />
            </a>
          </div>
          <!--body-->
          <div
            class="relative p-12 flex-col items-center justify-center text-center"
          >
            <h1 class="text-gray-500 text-3.5xl font-gilroy-bold">
              Thanks!
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "@/components/Navbars/Navbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";
// import ChatIcon from "@/components/Icons/ChatIcon.vue";
import MinusIcon from "@/components/Icons/MinusIcon.vue";
import SubscribeIcon from "@/components/Icons/SubscribeIcon.vue";
import SentIcon from "@/components/Icons/SentIcon.vue";
import SubscribtionIcon from "@/components/Icons/SubscribtionIcon.vue";
import subscribeBg from "@/assets/img/subscribe-bg.png";
import emailjs from "emailjs-com";
import { watchEffect } from "vue";
import vClickOutside from "click-outside-vue3";

import { useMeta } from "vue-meta";
import AOS from "aos";
import "aos/dist/aos.css";
var Tawk_API = Tawk_API || {};
// Tawk_LoadStart = new Date();
(function() {
  var s1 = document.createElement("script"),
    s0 = document.getElementsByTagName("script")[0];
  s1.async = true;
  s1.src = "https://embed.tawk.to/60fe9eca649e0a0a5ccdfa1a/1fbibo1ek";
  s1.charset = "UTF-8";
  s1.setAttribute("crossorigin", "*");
  s0.parentNode.insertBefore(s1, s0);
})();

Tawk_API.onLoad = function() {
  Tawk_API.showWidget();
};
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export function validName(name) {
  let validNamePattern = new RegExp("^[a-zA-Z]+(?:[-'\\s][a-zA-Z]+)*$");
  if (validNamePattern.test(name)) {
    return true;
  }
  return false;
}
export function validEmail(email) {
  let validEmailPattern = new RegExp(
    "^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$"
  );
  if (validEmailPattern.test(email)) {
    return true;
  }
  return false;
}
export default {
  setup() {
    // Add meta info
    // The object passed into useMeta is user configurable
    const { meta } = useMeta({
      title: "TalentFier",
      og: { image: "https://www.talentfier.com/img/logo.146485d7.png" }
    });

    watchEffect(() => {
      meta.description = "TalentFier App";
    });
    return { v$: useVuelidate() };
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      errors: [],

      subscribe: {
        email: "",
        first_name: "",
        last_name: ""
      },
      formSubmitted: false,
      formInvalid: false,
      subscribeBg,
      isLoading: false,
      showConfirmation: false,
      showSubscribe: false,
      phone_number: "+201005742469"
    };
  },
  validations() {
    return {
      subscribe: {
        first_name: {
          required,
          name_validation: {
            $validator: validName,
            $message:
              "Invalid First Name. Valid name only contain letters, dashes (-) and spaces"
          }
        },
        last_name: {
          required,
          name_validation: {
            $validator: validName,
            $message:
              "Invalid Last Name. Valid name only contain letters, dashes (-) and spaces"
          }
        },
        email: {
          required,
          email_validation: {
            $validator: validEmail,
            $message: "Invalid business email"
          }
        }
      }
    };
  },
  components: {
    Navbar,
    FooterComponent,
    // ChatIcon,
    MinusIcon,
    SentIcon,
    SubscribeIcon,
    SubscribtionIcon
  },
  methods: {
    toggleSubscribeModal: function() {
      this.showSubscribe = !this.showSubscribe;
    },
    onClickOutside() {
      this.showSubscribe = !this.showSubscribe;
    },
    async submit() {
      const result = await this.v$.subscribe.$validate();
      if (!result) {
        console.log("invalid");
        this.v$.subscribe.$validate();
        return;
      } else {
        this.isLoading = true;
        this.formSubmitted = false;
        emailjs.init("user_izlOpqWP0Iwr5F0O3XBYL");
        this.applicant_number = (Math.random() * 100000) | 0;
        emailjs
          .send(
            "service_1zsupij", //get this from emailjs dashboard
            "template_ep9rhwd", //get this from emailjs dashboard
            {
              email: this.email,
              first_name: this.first_name,
              last_name: this.last_name
            }
          )
          .then(
            response => {
              (this.formSubmitted = true),
                (this.showConfirmation = true),
                (this.showSubscribe = false),
                (this.isLoading = false),
                console.log("SUCCESS You just sent an email...", response);
            },
            error => {
              console.log("FAILED Throw an error to user...", error);
            }
          );
      }
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.$route.path == "/") this.showSubscribe = true;
    }, 5000);
  },
  created() {
    AOS.init({
      // Global settings: disable: false, // accepts following values:'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations'detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced) // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 0, // offset (in px) from the original trigger point delay: 0, // values from 0 to 3000, with step 50ms
      duration: 500, // values from 0 to 3000, with step 50ms easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: true, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom" // defines which position of the element regarding to window should trigger the animation
    });
  }
};
</script>
<style>
.tawk-agent-chat-bubble {
  background: #259dc6 !important;
}
</style>
