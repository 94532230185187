<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 35 35"
  >
    <g
      id="Group_3648"
      data-name="Group 3648"
      transform="translate(-11752 11705)"
    >
      <circle
        id="Ellipse_76"
        data-name="Ellipse 76"
        cx="17.5"
        cy="17.5"
        r="17.5"
        transform="translate(11752 -11705)"
        fill="#259dc6"
      />
      <line
        id="Line_720"
        data-name="Line 720"
        x2="16.667"
        transform="translate(11761.583 -11687.083)"
        fill="none"
        stroke="#fff"
        stroke-linecap="round"
        stroke-width="3"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>
