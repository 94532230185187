<template>
  <svg
    id="surface1"
    xmlns="http://www.w3.org/2000/svg"
    width="141.27"
    height="155.284"
    viewBox="0 0 141.27 155.284"
  >
    <path
      id="Path_10422"
      data-name="Path 10422"
      d="M105.831,67.221V37.834a3.4,3.4,0,0,0-.938-2.233L71.946,1a3.284,3.284,0,0,0-2.362-1H17.347A17.4,17.4,0,0,0,0,17.509v98.776a17.294,17.294,0,0,0,17.347,17.38H58.58a44.508,44.508,0,1,0,47.251-66.444ZM72.82,11.36l22.1,23.269H80.587a7.817,7.817,0,0,1-7.767-7.8ZM17.347,127.192A10.843,10.843,0,0,1,6.473,116.285V17.509A10.932,10.932,0,0,1,17.347,6.473h49V26.83A14.268,14.268,0,0,0,80.587,41.1H99.358V66.379c-.97-.032-1.747-.129-2.589-.129A45.159,45.159,0,0,0,67.253,77.384H26.15a3.236,3.236,0,1,0,0,6.472H61.234a51.492,51.492,0,0,0-5.7,10.033H26.15a3.237,3.237,0,0,0,0,6.473H53.433a44.418,44.418,0,0,0,1.942,26.862H17.347Zm79.391,21.652a38.028,38.028,0,1,1,38.027-38.028A38.068,38.068,0,0,1,96.737,148.844Zm0,0"
      fill="rgba(37,157,198,0.2)"
    />
    <path
      id="Path_10423"
      data-name="Path 10423"
      d="M78.787,208.157h32.785a3.237,3.237,0,1,0,0-6.473H78.787a3.237,3.237,0,1,0,0,6.473Zm0,0"
      transform="translate(-52.637 -140.515)"
      fill="rgba(37,157,198,0.2)"
    />
    <path
      id="Path_10424"
      data-name="Path 10424"
      d="M283.841,273a3.233,3.233,0,0,0,.777.1,2.793,2.793,0,0,0,.906-.13,8.286,8.286,0,0,1,7.606,8.254,3.236,3.236,0,0,0,6.472,0,14.79,14.79,0,0,0-11.748-14.468v-2.071a3.237,3.237,0,1,0-6.473,0v2.2a14.753,14.753,0,0,0,3.463,29.1,8.3,8.3,0,1,1-8.285,8.317,3.237,3.237,0,0,0-6.473,0,14.752,14.752,0,0,0,11.3,14.338v3.334a3.237,3.237,0,0,0,6.473,0v-3.237a14.768,14.768,0,0,0-3.01-29.225,8.283,8.283,0,0,1-1-16.506Zm0,0"
      transform="translate(-188.172 -182.149)"
      fill="rgba(37,157,198,0.2)"
    />
  </svg>
</template>

<script>
export default {};
</script>
