<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="134.398"
    height="150.475"
    viewBox="0 0 134.398 150.475"
  >
    <g id="file_1_" data-name="file (1)" transform="translate(-27.352)">
      <g id="Group_3505" data-name="Group 3505" transform="translate(27.352)">
        <g
          id="Group_3489"
          data-name="Group 3489"
          transform="translate(100.602 24.087)"
        >
          <g id="Group_3488" data-name="Group 3488">
            <path
              id="Path_10429"
              data-name="Path 10429"
              d="M372.594,107.93a2.939,2.939,0,0,1-2.939-2.939v-20.1a2.939,2.939,0,0,1,5.878,0v20.1A2.939,2.939,0,0,1,372.594,107.93Z"
              transform="translate(-369.655 -81.956)"
              fill="rgba(37,157,198,0.2)"
            />
          </g>
        </g>
        <g
          id="Group_3491"
          data-name="Group 3491"
          transform="translate(100.589 14.63)"
        >
          <g id="Group_3490" data-name="Group 3490">
            <circle
              id="Ellipse_28"
              data-name="Ellipse 28"
              cx="2.939"
              cy="2.939"
              r="2.939"
              fill="rgba(37,157,198,0.2)"
            />
          </g>
        </g>
        <g id="Group_3494" data-name="Group 3494" transform="translate(0)">
          <path
            id="Path_10430"
            data-name="Path 10430"
            d="M161.75,30.876A30.854,30.854,0,0,0,112.131,6.362H38.048a10.708,10.708,0,0,0-10.7,10.7V123.969a10.708,10.708,0,0,0,10.7,10.7h9.326v5.114a10.708,10.708,0,0,0,10.7,10.7h76.843a10.708,10.708,0,0,0,10.7-10.7V99.015a2.939,2.939,0,1,0-5.878,0v40.764a4.824,4.824,0,0,1-4.818,4.818H58.069a4.823,4.823,0,0,1-4.818-4.818v-5.114h61.639a10.708,10.708,0,0,0,10.7-10.7V61.3a30.982,30.982,0,0,0,14.143-.838V72.444a2.939,2.939,0,1,0,5.878,0V58.006a30.9,30.9,0,0,0,16.142-27.13ZM119.709,123.97a4.824,4.824,0,0,1-4.818,4.818H38.048a4.823,4.823,0,0,1-4.818-4.818V17.058a4.823,4.823,0,0,1,4.818-4.818h68.234a30.714,30.714,0,0,0-6.245,17.332H49.989a2.939,2.939,0,0,0-2.939,2.939V44.923a2.939,2.939,0,0,0,2.939,2.939h52.96a2.926,2.926,0,0,0,1.778-.6,31.051,31.051,0,0,0,14.982,12.4Zm-19.7-88.52v6.535H52.928V35.45Zm30.87,20.424a25,25,0,1,1,24.992-25A25.023,25.023,0,0,1,130.88,55.874Z"
            transform="translate(-27.352)"
            fill="rgba(37,157,198,0.2)"
          />
          <g
            id="Group_3493"
            data-name="Group 3493"
            transform="translate(112.383 82.744)"
          >
            <g id="Group_3492" data-name="Group 3492">
              <path
                id="Path_10431"
                data-name="Path 10431"
                d="M412.674,287.413a2.939,2.939,0,1,1,2.863-2.272A2.967,2.967,0,0,1,412.674,287.413Z"
                transform="translate(-409.742 -281.541)"
                fill="rgba(37,157,198,0.2)"
              />
            </g>
          </g>
        </g>
        <g
          id="Group_3504"
          data-name="Group 3504"
          transform="translate(19.698 58.084)"
        >
          <g id="Group_3503" data-name="Group 3503">
            <g id="Group_3496" data-name="Group 3496">
              <g id="Group_3495" data-name="Group 3495">
                <path
                  id="Path_10432"
                  data-name="Path 10432"
                  d="M150.276,203.511H97.316a2.939,2.939,0,0,1,0-5.878h52.96a2.939,2.939,0,0,1,0,5.878Z"
                  transform="translate(-94.377 -197.633)"
                  fill="rgba(37,157,198,0.2)"
                />
              </g>
            </g>
            <g
              id="Group_3498"
              data-name="Group 3498"
              transform="translate(0 13.94)"
            >
              <g id="Group_3497" data-name="Group 3497">
                <path
                  id="Path_10433"
                  data-name="Path 10433"
                  d="M150.276,250.941H97.316a2.939,2.939,0,0,1,0-5.878h52.96a2.939,2.939,0,1,1,0,5.878Z"
                  transform="translate(-94.377 -245.063)"
                  fill="rgba(37,157,198,0.2)"
                />
              </g>
            </g>
            <g
              id="Group_3500"
              data-name="Group 3500"
              transform="translate(0 27.879)"
            >
              <g id="Group_3499" data-name="Group 3499">
                <path
                  id="Path_10434"
                  data-name="Path 10434"
                  d="M150.276,298.371H97.316a2.939,2.939,0,0,1,0-5.878h52.96a2.939,2.939,0,1,1,0,5.878Z"
                  transform="translate(-94.377 -292.493)"
                  fill="rgba(37,157,198,0.2)"
                />
              </g>
            </g>
            <g
              id="Group_3502"
              data-name="Group 3502"
              transform="translate(0 41.819)"
            >
              <g id="Group_3501" data-name="Group 3501">
                <path
                  id="Path_10435"
                  data-name="Path 10435"
                  d="M150.276,345.8H97.316a2.939,2.939,0,0,1,0-5.878h52.96a2.939,2.939,0,0,1,0,5.878Z"
                  transform="translate(-94.377 -339.923)"
                  fill="rgba(37,157,198,0.2)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>
