<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="38.519"
    height="22.429"
    viewBox="0 0 38.519 22.429"
  >
    <g
      id="Group_25"
      data-name="Group 25"
      transform="translate(-544.672 469.05) rotate(-90)"
    >
      <path
        id="Path_641"
        data-name="Path 641"
        d="M27.893,13.5l-9.447,9.447L9,13.5"
        transform="translate(439.389 558.477)"
        fill="none"
        stroke="currentColor"
        stroke-linecap="square"
        stroke-width="2.5"
      />
      <path
        id="Path_642"
        data-name="Path 642"
        d="M12,38.278V7.5"
        transform="translate(445.836 538.422)"
        fill="none"
        stroke="currentColor"
        stroke-linecap="square"
        stroke-width="2.5"
      />
      <path
        id="Path_643"
        data-name="Path 643"
        d="M12,19.723v0Z"
        transform="translate(445.836 561.701)"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-width="2.5"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>
