<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="39.372"
    height="37.655"
    viewBox="0 0 39.372 37.655"
  >
    <g id="Group_3650" data-name="Group 3650" transform="translate(0)">
      <path
        id="Path_10566"
        data-name="Path 10566"
        d="M660.89,382.046a.884.884,0,0,1,.876-.876h3.5a.876.876,0,1,1,0,1.751h-3.5A.878.878,0,0,1,660.89,382.046Z"
        transform="translate(-650.819 -363.656)"
        fill="#259dc6"
      />
      <path
        id="Path_10567"
        data-name="Path 10567"
        d="M660.89,372.046a.884.884,0,0,1,.876-.876H679.28a.876.876,0,1,1,0,1.751H661.766A.878.878,0,0,1,660.89,372.046Z"
        transform="translate(-650.819 -358.034)"
        fill="#259dc6"
      />
      <path
        id="Path_10568"
        data-name="Path 10568"
        d="M660.89,362.046a.884.884,0,0,1,.876-.876H679.28a.876.876,0,1,1,0,1.751H661.766A.878.878,0,0,1,660.89,362.046Z"
        transform="translate(-650.819 -352.413)"
        fill="#259dc6"
      />
      <path
        id="Path_10569"
        data-name="Path 10569"
        d="M677.078,356.758a2.367,2.367,0,0,0-.788-1.051l-4.685-3.678v-3.853a2.7,2.7,0,0,0-1.751-2.5V343.8a2.635,2.635,0,0,0-2.627-2.627H647.961a2.635,2.635,0,0,0-2.627,2.627v1.883a2.625,2.625,0,0,0-1.751,2.5v3.853l-4.685,3.678a2.58,2.58,0,0,0-.788,1.007v.044a2.944,2.944,0,0,0-.219,1.007V376.2a2.785,2.785,0,0,0,.744,1.839,2.816,2.816,0,0,0,1.883.788H674.67a2.764,2.764,0,0,0,1.839-.744,2.565,2.565,0,0,0,.744-1.808V357.765A1.9,1.9,0,0,0,677.078,356.758Zm-5.473-2.54,3.5,2.758-3.5,3.459ZM647.085,343.8a.884.884,0,0,1,.876-.876h19.266a.878.878,0,0,1,.876.876v1.751H647.085Zm-1.751,4.378a.884.884,0,0,1,.876-.876h22.768a.878.878,0,0,1,.876.876v13.968l-.088.044-4.116,4.028-4.116-4.028-2.1-2.058a2.616,2.616,0,0,0-3.678,0l-2.1,2.058-4.116,4.028-4.116-4.028-.088-.044Zm-1.751,6.042v6.217l-3.5-3.415Zm-3.941,21.63V359.034l8.626,8.407Zm1.27,1.226L656.98,361.4a.85.85,0,0,1,1.2-.026l.026.026,16.069,15.675Zm34.634-1.226-8.626-8.407,8.626-8.407Z"
        transform="translate(-637.89 -341.17)"
        fill="#259dc6"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>
