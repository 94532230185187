<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="175.351"
    height="188.086"
    viewBox="0 0 175.351 188.086"
  >
    <path
      id="Path_10419"
      data-name="Path 10419"
      d="M63.277,111.717a50.94,50.94,0,1,1,50.94-50.94,3.918,3.918,0,1,0,7.837,0,58.777,58.777,0,1,0-58.777,58.777,3.918,3.918,0,0,0,0-7.837Zm0-82.287c-8.873,0-18.368,5.948-19.577,19.238l7.806.707C52.552,37.85,61.5,37.266,63.277,37.266c2.757,0,11.755.723,11.755,10.041,0,4.916-3.113,7.306-7.392,10.8-3.909,3.186-9.261,7.151-9.261,14.43v3.918h7.837V72.532c0-3.391,2.618-5.292,6.375-8.358,4.363-3.554,10.033-7.98,10.033-16.867C82.624,36.614,74.995,29.429,63.277,29.429Zm-4.9,62.7h7.837V84.287H58.379ZM165.156,76.451H94.624c-8.642,0-16.653,7.032-16.653,15.674v66.614c0,8.644,8.011,15.674,16.653,15.674h21.551l10.58,14.1a3.915,3.915,0,0,0,6.27,0l10.58-14.1h21.551c8.644,0,14.694-7.03,14.694-15.674V92.124C179.851,83.482,173.8,76.451,165.156,76.451Zm6.857,82.287c0,4.32-2.537,7.837-6.857,7.837H141.646a3.918,3.918,0,0,0-3.135,1.569l-8.621,11.491-8.621-11.491a3.918,3.918,0,0,0-3.135-1.569H94.624c-4.322,0-8.817-3.517-8.817-7.837V92.124c0-4.322,4.494-7.837,8.817-7.837h70.532c4.32,0,6.857,3.515,6.857,7.837Zm-47.021-19.592h7.837V99.961h-7.837Zm0,15.674h7.837v-7.837h-7.837Z"
      transform="translate(-4.5 -2)"
      fill="rgba(37,157,198,0.2)"
    />
  </svg>
</template>

<script>
export default {};
</script>
