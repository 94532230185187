<template>
  <svg
    id="Group_3509"
    data-name="Group 3509"
    xmlns="http://www.w3.org/2000/svg"
    width="184.019"
    height="154.772"
    viewBox="0 0 184.019 154.772"
  >
    <path
      id="Path_10436"
      data-name="Path 10436"
      d="M30.183,13C16.992,13,6,23.3,6,36.2V163.589a4.183,4.183,0,1,0,8.366,0V53.654h97.386v12.81a4.183,4.183,0,1,0,8.366,0V36.2c0-12.877-10.861-23.2-24.052-23.2Zm0,8.366H96.066c8.892,0,15.686,6.648,15.686,14.837v9.085H14.366V36.2C14.366,28.036,21.291,21.366,30.183,21.366ZM49.53,30.059a4.188,4.188,0,0,0,.392,8.366H76.2a4.183,4.183,0,1,0,0-8.366H49.922Q49.726,30.05,49.53,30.059Zm16.144,46.6A8.207,8.207,0,0,0,57.634,84.9v47.059a8.207,8.207,0,0,0,8.039,8.235h68.039a8.207,8.207,0,0,0,8.039-8.235V84.9a8.207,8.207,0,0,0-8.039-8.235ZM161.1,79.732a4.183,4.183,0,0,0-2.484,7.19l17.19,17.32H151.948q-.2-.009-.392,0a4.188,4.188,0,1,0,.392,8.366H175.8l-17.19,17.32a4.183,4.183,0,1,0,5.883,5.948l24.313-24.51a4.183,4.183,0,0,0,0-5.882L164.5,81.039A4.183,4.183,0,0,0,161.1,79.732ZM66,85.026h67.386v.523l-33.66,22.484L66,85.549Zm0,10.523L97.373,116.6a4.183,4.183,0,0,0,4.641,0l31.373-21.046v36.275H66Zm49.869,54.445a4.183,4.183,0,0,0-4.118,4.248v9.346a4.183,4.183,0,1,0,8.366,0v-9.346a4.183,4.183,0,0,0-4.248-4.248Z"
      transform="translate(-6 -13)"
      fill="rgba(37,157,198,0.2)"
    />
  </svg>
</template>

<script>
export default {};
</script>
