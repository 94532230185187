<template>
  <svg width="11.272" height="11.272" viewBox="0 0 11.272 11.272">
    <g
      data-name="Group 640"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-width="2.5"
    >
      <path data-name="Line 60" d="M9.504 1.768L1.768 9.504" />
      <path data-name="Line 61" d="M1.768 1.768l7.736 7.736" />
    </g>
  </svg>
</template>

<script>
export default {};
</script>
