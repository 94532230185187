<template>
  <div>
    <Breadcrumb :crumbs="crumbs" @selected="selected">
      <div class="flex items-center justify-center">
        <div class="relative">
          <h2
            class="relative text-3xl md:text-7xl
 font-gilroy-bold text-center z-50"
          >
            Recruiter Sign Up
          </h2>
          <SignupIcon
            class="absolute -top-1/2 -left-1/3 z-0
"
          />
        </div>
      </div>
    </Breadcrumb>

    <main>
      <div
        class="container relative py-16 flex bg-contain bg-top bg-no-repeat flex-col content-center items-center justify-center"
        :style="{ backgroundImage: 'url(' + formsBg + ')' }"
      >
        <div
          class="w-full lg:w-8/12 flex-auto px-4 font-gilroy-regular
 rounded-lg shadow-lg bg-white lg:px-10 py-10"
        >
          <div class="py-5" v-if="formSubmitted">
            Thank you for signing up,
            <span class="text-primary">{{ first_name }}</span
            >. we have received your request and will get back to you soon.
          </div>

          <form
            :class="{ formSubmitted: formSubmitted }"
            id="signup-form"
            v-if="!isLoading && !formSubmitted"
          >
            <label class="text-primary-darkest text-xl lg:text-1.5xl flex py-3"
              >General Info
            </label>
            <div
              class="grid grid-cols-1 lg:grid-cols-2 gap-4 relative w-full mb-3"
            >
              <div class="relative w-full mb-3">
                <input
                  type="text"
                  class="border-1.5 border-gray-50 px-3 py-3 placeholder-gray-500 placeholder-opacity-40 text-gray-500 bg-white rounded-full text-base lg:text-lg focus:outline-none w-full ease-linear transition-all duration-150"
                  v-model="v$.company_website.$model"
                  @blur="v$.company_website.$touch;"
                  placeholder="Company Website

"
                />

                <!-- Error Message -->
                <div
                  v-for="(error, index) of v$.company_website.$errors"
                  :key="index"
                >
                  <div class="text-red-500">{{ error.$message }}</div>
                </div>
                <!-- <div
                  v-if="!isFreelancer && !validUrl(this.company_website)"
                  class="text-red-500"
                >
                  Valid URl required, or check if freelancer
                </div> -->
                <div
                  v-if="this.company_website && !validUrl(this.company_website)"
                  class="text-red-500"
                >
                  Valid URl required, or check if freelancer
                </div>

                <div
                  v-for="(error, index) of v$.company_website.$errors"
                  :key="index"
                >
                  <div
                    v-if="
                      this.company_website && !validUrl(this.company_website)
                    "
                    class="text-red-500"
                  >
                    Valid URl required, or check if freelancer
                  </div>
                  <div
                    v-if="!isFreelancer && !validUrl(this.company_website)"
                    class="text-red-500"
                  >
                    Valid URl required, or check if freelancer
                  </div>
                </div>
              </div>
              <div class="flex flex-col">
                <div class="flex items-center ml-4 mb-3">
                  <input
                    type="checkbox"
                    v-model="isFreelancer"
                    id="isFreelancer"
                    class="opacity-0 absolute w-5 h-5"
                  />

                  <div
                    class="bg-white border-2 rounded border-primary border-opacity-70 w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500"
                  >
                    <svg
                      class="fill-current hidden w-3 h-3 text-primary pointer-events-none"
                      version="1.1"
                      viewBox="0 0 17 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="none" fill-rule="evenodd">
                        <g
                          transform="translate(-9 -11)"
                          fill="currentColor"
                          fill-rule="nonzero"
                        >
                          <path
                            d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <label
                    for="isFreelancer"
                    class="flex flex-col cursor-pointer leading-tight"
                  >
                    <p>I'm a freelancer recruiter</p>
                    <span class="text-gray-500 text-opacity-50"
                      >(I don't have website)
                    </span>
                  </label>
                </div>
                <!-- Error Message -->
                <!-- <div v-if="!this.isFreelancer && !this.company_website">
                  <div class="text-red-500">chech if no website available</div>
                </div> -->
                <!-- <span
                  >the text is {{ isChecked ? "optional" : "required" }}</span
                > -->
              </div>
            </div>

            <div class="relative w-full mb-3">
              <input
                type="email"
                class="border-1.5 border-gray-50 px-3 py-3 placeholder-gray-500 placeholder-opacity-40 text-gray-500 bg-white rounded-full text-base lg:text-lg focus:outline-none w-full ease-linear transition-all duration-150"
                v-model="v$.email.$model"
                placeholder="Your Business Email

"
              />
              <!-- Error Message -->
              <div v-for="(error, index) of v$.email.$errors" :key="index">
                <div class="text-red-500">{{ error.$message }}</div>
              </div>
            </div>
            <div
              class="grid grid-cols-1 lg:grid-cols-2 gap-4 relative w-full mb-3"
            >
              <div class="flex flex-col">
                <input
                  type="text"
                  class="border-1.5 border-gray-50 px-3 py-3 placeholder-gray-500 placeholder-opacity-40 text-gray-500 bg-white rounded-full text-base lg:text-lg focus:outline-none w-full ease-linear transition-all duration-150"
                  v-model="v$.first_name.$model"
                  @blur="v$.first_name.$touch;"
                  placeholder="First Name



"
                />
                <!-- Error Message -->
                <div
                  v-for="(error, index) of v$.first_name.$errors"
                  :key="index"
                >
                  <div class="text-red-500">{{ error.$message }}</div>
                </div>
              </div>
              <div class="flex flex-col">
                <input
                  type="text"
                  class="border-1.5 border-gray-50 px-3 py-3 placeholder-gray-500 placeholder-opacity-40 text-gray-500 bg-white rounded-full text-base lg:text-lg focus:outline-none w-full ease-linear transition-all duration-150"
                  v-model="v$.last_name.$model"
                  @blur="v$.last_name.$touch;"
                  placeholder="Last Name
"
                />
                <!-- Error Message -->
                <div
                  v-for="(error, index) of v$.last_name.$errors"
                  :key="index"
                >
                  <div class="text-red-500">{{ error.$message }}</div>
                </div>
              </div>
            </div>

            <div class="relative mb-3 w-full flex flex-wrap items-stretch">
              <!-- <span
                class="absolute z-10 py-3 px-3 border-r border-gray-50 h-full text-base lg:text-lg text-opacity-40 font-normal text-gray-500 text-center flex items-center justify-center"
              >
                +20
              </span> -->
              <input
                type="text"
                class="border-1.5 border-gray-50 px-3 py-3 placeholder-gray-500 placeholder-opacity-40 text-gray-500 bg-white rounded-full text-base lg:text-lg focus:outline-none w-full ease-linear transition-all duration-150"
                v-model="v$.mobile.$model"
                @blur="v$.mobile.$touch;"
                placeholder="Mobile Number


"
              />
              <!-- Error Message -->
              <div v-for="(error, index) of v$.mobile.$errors" :key="index">
                <div class="text-red-500">{{ error.$message }}</div>
              </div>
            </div>

            <label class="text-primary-darkest text-xl lg:text-1.5xl flex py-3"
              >Business Info
            </label>
            <div
              class="grid grid-cols-1 lg:grid-cols-2 gap-4 relative w-full mb-3"
            >
              <div class="flex flex-col">
                <select
                  v-model="v$.hiring.$model"
                  @click="v$.hiring.$touch()"
                  class="appearance-none bg-chevron-down pr-10 bg-pselect bg-no-repeat outline-none w-full border-1.5 border-gray-50 px-3 py-3 placeholder-gray-500 placeholder-opacity-40 text-gray-500 bg-white rounded-full text-base lg:text-lg"
                >
                  <option value="" disabled>
                    Average monthly vacancies
                  </option>
                  <option value="<3 vacancies"> &lt; 3 vacancies</option>
                  <option value="3-10 vacancies"> 3-10 vacancies</option>
                  <option value=">10 vacancies"> > 10 vacancies</option>
                </select>
                <!-- Error Message -->
                <div v-for="(error, index) of v$.hiring.$errors" :key="index">
                  <div class="text-red-500">{{ error.$message }}</div>
                </div>
              </div>
              <div class="flex flex-col">
                <select
                  v-model="v$.company_size.$model"
                  @click="v$.company_size.$touch()"
                  class="appearance-none bg-chevron-down pr-10 bg-pselect bg-no-repeat outline-none w-full border-1.5 border-gray-50 px-3 py-3 placeholder-gray-500 placeholder-opacity-40 text-gray-500 bg-white rounded-full text-base lg:text-lg"
                >
                  <option value="" disabled>
                    Company Size (No of Employees)
                  </option>
                  <option value="1-10 Employees">
                    1-10 Employees
                  </option>
                  <option value="10-50 Employees">
                    10-50 Employees
                  </option>

                  <option value="50-100 Employees">
                    50-100 Employees
                  </option>

                  <option value="100-500 Employees">
                    100-500 Employees
                  </option>
                </select>
                <!-- Error Message -->
                <div
                  v-for="(error, index) of v$.company_size.$errors"
                  :key="index"
                >
                  <div class="text-red-500">{{ error.$message }}</div>
                </div>
              </div>
            </div>
            <div
              class="grid grid-cols-1 lg:grid-cols-2 gap-4 relative w-full mb-3"
            >
              <div class="flex flex-col">
                <select
                  v-model="v$.reference.$model"
                  @click="v$.reference.$touch()"
                  class="appearance-none bg-chevron-down bg-pselect bg-no-repeat outline-none w-full border-1.5 border-gray-50 px-3 py-3 placeholder-gray-500 placeholder-opacity-40 text-gray-500 bg-white rounded-full text-base lg:text-lg"
                >
                  <option value="" disabled>
                    How did you hear about us?
                  </option>
                  <option value="Facebook">Facebook</option>
                  <option value="Linkedin">Linkedin</option>
                  <option value="Google Search">Google Search</option>
                  <option value="Friend">Friend</option>
                  <option value="Friend">Event</option>
                  <option value="Friend">Other</option>
                </select>
                <!-- Error Message -->
                <div
                  v-for="(error, index) of v$.reference.$errors"
                  :key="index"
                >
                  <div class="text-red-500">{{ error.$message }}</div>
                </div>
              </div>
              <input
                type="text"
                class="border-1.5 border-gray-50 px-3 py-3 placeholder-gray-500 placeholder-opacity-40 text-gray-500 bg-white rounded-full text-base lg:text-lg focus:outline-none w-full ease-linear transition-all duration-150"
                v-model="code"
                placeholder="Promo Code
"
              />
            </div>
            <div class="flex flex-col items-center justify-center mt-6">
              <vue-recaptcha
                v-if="showRecaptcha"
                @verify="recaptchaVerified"
                @expire="recaptchaExpired"
                @fail="recaptchaFailed"
                ref="vueRecaptcha"
                sitekey="6Ld-2PMeAAAAAGJOjXcx8HFULKxrRvsHDpF-xseY
"
              ></vue-recaptcha>
              <!-- Error Message -->
              <div class="text-red-500" v-if="showRobotError">
                Verification required
              </div>
            </div>
            <div class="text-center mt-6">
              <!-- <button
                class="bg-primary
 text-white active:bg-opacity-50 text-base px-6 py-3
rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1
w-full ease-linear transition-all duration-150 cursor-pointer"
                @click.prevent="submit"
                type="submit"
                :class="v$.$invalid || robot ? 'bg-opacity-50' : ''"
                :disabled="v$.$invalid || robot"
              >
                Submit
              </button> -->
              <button
                class="bg-primary
 text-white active:bg-opacity-50 text-base px-6 py-3
rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1
w-full ease-linear transition-all duration-150 cursor-pointer"
                @click.prevent="submit"
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
          <div
            class="w-full flex flex-col items-center justify-center"
            v-if="isLoading"
          >
            <div class="loading-container">
              <div class="loader">
                <LoadingIcon />
              </div>
              <h4 class="loading-text">SENDING...</h4>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import SignupIcon from "@/components/Icons/SignupIcon.vue";
import LoadingIcon from "@/components/Icons/LoadingIcon.vue";
import formsBg from "@/assets/img/forms-bg.png";
import emailjs from "emailjs-com";
import { VueRecaptcha } from "vue-recaptcha";
import useVuelidate from "@vuelidate/core";
import {
  requiredIf,
  required,
  minLength,
  numeric
  // requiredUnless
  // url
} from "@vuelidate/validators";
import { watchEffect } from "vue";
import { useMeta } from "vue-meta";
export function validName(name) {
  let validNamePattern = new RegExp("^[a-zA-Z]+(?:[-'\\s][a-zA-Z]+)*$");
  if (validNamePattern.test(name)) {
    return true;
  }
  return false;
}
export function validEmail(email) {
  let validEmailPattern = new RegExp(
    "^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$"
  );
  if (validEmailPattern.test(email)) {
    return true;
  }
  return false;
}
export function validUrl(company_website) {
  let validUrlPattern = new RegExp(
    "^((ftp|http|https)://)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(.[a-zA-Z]+)+((/)[w#]+)*(/w+?[a-zA-Z0-9_]+=w+(&[a-zA-Z0-9_]+=w+)*)?$"
  );
  if (validUrlPattern.test(company_website)) {
    return true;
  }
  return false;
}
export default {
  setup() {
    // Add meta info
    // The object passed into useMeta is user configurable
    const { meta } = useMeta({
      title: "Sign Up"
    });

    watchEffect(() => {
      meta.description =
        "Create your account, and start now to use pre-hiring tests to identify talent easier and make your hiring faster.";
    });
    return { v$: useVuelidate() };
  },
  data() {
    return {
      sitekey: process.env.VUE_APP_SITEKEY,
      robot: true,
      showRobotError: false,
      showRecaptcha: true,
      crumbs: ["Home", "Recruiter Sign Up"],
      formsBg,
      company_website: "",
      email: "",
      isFreelancer: false,
      first_name: "",
      last_name: "",
      mobile: "",
      hiring: "",
      company_size: "",
      reference: "",
      code: "",
      formSubmitted: false,
      isLoading: false,
      formInvalid: false
    };
  },
  validations() {
    return {
      first_name: {
        required,
        name_validation: {
          $validator: validName,
          $message:
            "Invalid First Name. Valid name only contain letters, dashes (-) and spaces"
        }
      },
      last_name: {
        required,
        name_validation: {
          $validator: validName,
          $message:
            "Invalid Last Name. Valid name only contain letters, dashes (-) and spaces"
        }
      },
      email: {
        required,
        email_validation: {
          $validator: validEmail,
          $message: "Invalid business email"
        }
      },
      // email: { required, email },

      company_website: {
        // requiredIfNotFreelancer: requiredIf(!this.isFreelancer),
        // required: requiredUnless("isChecked"),
        required: requiredIf(() => {
          return !this.isFreelancer && validUrl;
        })
        // url_validation: {
        //   $validator: validUrl,
        //   $message: "Valid URL is required"
        // }
      },
      // freelancer: {
      //   // requiredIf: requiredIf(() => {
      //   //   return !this.company_website;
      //   // }),
      //   sameAs: sameAs(() => true) // add this line in validation
      // },
      mobile: { required, numeric, min: minLength(11) },
      hiring: { required },
      company_size: { required },
      reference: { required }
    };
  },
  components: { Breadcrumb, SignupIcon, LoadingIcon, VueRecaptcha },
  computed: {
    isChecked: function() {
      return this.isFreelancer;
    }
  },

  methods: {
    selected(crumb) {
      console.log(crumb);
    },
    getURL(src) {
      // // console.log(path) return
      require(`@/assets/img/${src}`).default;
    },
    async submit() {
      const result = await this.v$.$validate();
      if (!result) {
        if (this.robot) {
          this.showRobotError = true;
          return;
        }
        console.log("invalid");

        return;
      } else {
        this.isLoading = true;
        this.formSubmitted = false;
        emailjs.init("user_izlOpqWP0Iwr5F0O3XBYL");
        this.applicant_number = (Math.random() * 100000) | 0;
        emailjs
          .send(
            "service_1zsupij", //get this from emailjs dashboard
            "template_osnvvh5", //get this from emailjs dashboard
            {
              company_website: this.company_website,
              email: this.email,
              first_name: this.first_name,
              last_name: this.last_name,
              mobile: this.mobile,
              hiring: this.hiring,
              company_size: this.company_size,
              reference: this.reference,
              freelancer: this.isFreelancer,
              promo_code: this.code,
              applicant_number: this.applicant_number
            }
          )
          .then(
            response => {
              (this.formSubmitted = true),
                (this.isLoading = false),
                console.log("SUCCESS You just sent an email...", response);
            },
            error => {
              console.log("FAILED Throw an error to user...", error);
              this.isLoading = false;
            }
          );
      }
    },
    // submit(e) {
    //   e.preventDefault();

    //   if (this.robot) {
    //     this.showRobotError = true;
    //     return;
    //   } else {
    //     this.isLoading = true;
    //     this.formSubmitted = false;
    //     emailjs.init("user_izlOpqWP0Iwr5F0O3XBYL");
    //     this.applicant_number = (Math.random() * 100000) | 0;
    //     emailjs
    //       .send(
    //         "service_1zsupij", //get this from emailjs dashboard
    //         "template_osnvvh5", //get this from emailjs dashboard
    //         {
    //           company_website: this.company_website,
    //           email: this.email,
    //           first_name: this.first_name,
    //           last_name: this.last_name,
    //           mobile: this.mobile,
    //           hiring: this.hiring,
    //           company_size: this.company_size,
    //           reference: this.reference,
    //           freelancer: this.isFreelancer,
    //           promo_code: this.code,
    //           applicant_number: this.applicant_number
    //         }
    //       )
    //       .then(
    //         response => {
    //           (this.formSubmitted = true),
    //             (this.isLoading = false),
    //             console.log("SUCCESS You just sent an email...", response);
    //         },
    //         error => {
    //           console.log("FAILED Throw an error to user...", error);
    //           this.isLoading = false;
    //         }
    //       );
    //   }
    // },
    recaptchaVerified() {
      this.robot = false;
      this.showRobotError = false;
    },
    recaptchaExpired() {
      this.robot = true;
      this.$refs.vueRecaptcha.reset();
    },
    recaptchaFailed() {},

    filterKey(e) {
      const key = e.key;

      // If is '.' key, stop it
      if (key === ".") return e.preventDefault();

      // OPTIONAL
      // If is 'e' key, stop it
      if (key === "e") return e.preventDefault();
    },

    // This can also prevent copy + paste invalid character
    filterInput(e) {
      e.target.value = e.target.value.replace(/[^0-9]+/g, "");
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    //     validEmail: function (email) { var re =
    // /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // return re.test(email); },
    validEmail: function(email) {
      var re = /^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/;
      return re.test(email);
    },
    validNumber: function(mobile) {
      var re = /^[0-9+]*$/;
      return re.test(mobile);
    },
    validText: function(input) {
      var re = /^[a-zA-Z\u0600-\u06FF ]+$/;
      return re.test(input);
    },
    validUrl: function(input) {
      var re = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
      return re.test(input);
    }
  }
};
</script>
