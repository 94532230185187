<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="35.775"
    viewBox="0 0 24 35.775"
  >
    <g id="Group_72" data-name="Group 72" transform="translate(-629 -572)">
      <g id="arrow-down-circle" transform="translate(625.848 585.831)">
        <path
          id="Path_641"
          data-name="Path 641"
          d="M17.034,13.5l-4.017,4.017L9,13.5"
          transform="translate(2.152 3.678)"
          fill="none"
          stroke="#31418f"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
      </g>
      <g id="Group_62" data-name="Group 62" transform="translate(629 572)">
        <path
          id="Path_703"
          data-name="Path 703"
          d="M0,0H24V24H0Z"
          fill="none"
        />
        <rect
          id="Rectangle_11"
          data-name="Rectangle 11"
          width="12"
          height="18"
          rx="4"
          transform="translate(6 3)"
          fill="none"
          stroke="#31418f"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
        <line
          id="Line_9"
          data-name="Line 9"
          y2="4"
          transform="translate(12 7)"
          fill="none"
          stroke="#31418f"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>
