import { createWebHistory, createRouter } from "vue-router";
const Index = () => import(/* webpackPrefetch: true */ "@/views/Index.vue");
const Pricing = () => import(/* webpackPrefetch: true */ "@/views/Pricing.vue");
const Contact = () => import(/* webpackPrefetch: true */ "@/views/Contact.vue");
const About = () => import(/* webpackPrefetch: true */ "@/views/About.vue");
const Careers = () => import(/* webpackPrefetch: true */ "@/views/Careers.vue");
const SignUp = () => import(/* webpackPrefetch: true */ "@/views/SignUp.vue");
const CouponRequest = () =>
  import(/* webpackPrefetch: true */ "@/views/CouponRequest.vue");
const Faq = () => import(/* webpackPrefetch: true */ "@/views/Faq.vue");
const PageNotFound = () =>
  import(/* webpackPrefetch: true */ "@/views/404.vue");
const HowToUse = () =>
  import(/* webpackPrefetch: true */ "@/views/HowToUse.vue");

const routes = [
  {
    path: "/pricing",
    component: Pricing,
    meta: [
      {
        title: "Pricing",
        discription: "Pricing",
      },
    ],
  },
  {
    path: "/how-to-use",
    component: HowToUse,
    meta: [
      {
        title: "Pricing",
        discription: "TalentFier Packages",
      },
    ],
  },
  {
    path: "/contact-us",
    component: Contact,
    meta: [
      {
        title: "Contact Us",
        discription: "Contact Us",
      },
    ],
  },
  {
    path: "/about-us",
    component: About,
    meta: [
      {
        title: "About Us",
        discription: "About Us",
      },
    ],
  },
  {
    path: "/sign-up",
    component: SignUp,
    meta: [
      {
        title: "Sign Up",
        discription: "Sign Up",
      },
    ],
  },
  {
    path: "/coupon-request",
    component: CouponRequest,
    meta: [
      {
        title: "Coupon Request",
        discription: "Coupon Request",
      },
    ],
  },
  {
    path: "/faq",
    component: Faq,
    meta: [
      {
        title: "FAQ",
        discription: "FAQ",
      },
    ],
  },
  {
    path: "/careers",
    component: Careers,
    meta: [
      {
        title: "Careers",
        discription: "Careers",
      },
    ],
  },
  {
    path: "/",
    component: Index,
    meta: {
      title: "Home",
      discription: "TalentFier Home",
    },
  },
  { path: "/:pathMatch(.*)*", component: PageNotFound },
  // { path: "/:pathMatch(.*)*", redirect: "/" }
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, SavedPosition) {
    if (to.hash) {
      const el = window.location.href.split("#")[1];
      if (el.length) {
        document.getElementById(el).scrollIntoView({ behavior: "smooth" });
      }
    } else if (SavedPosition) {
      return SavedPosition;
    } else {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    }
  },
});

export default router;
