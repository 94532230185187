<template>
  <svg
    id="Group_20"
    data-name="Group 20"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="120.465"
    height="115.5"
    viewBox="0 0 120.465 115.5"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        y1="1.077"
        x2="0.5"
        y2="-0.051"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#31418f" />
        <stop offset="1" stop-color="#239cc6" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="0.5"
        y1="2.164"
        x2="0.5"
        y2="-0.212"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0.494" stop-color="#31418f" />
        <stop offset="0.565" stop-color="#304792" />
        <stop offset="0.661" stop-color="#2d589d" />
        <stop offset="0.773" stop-color="#2875ae" />
        <stop offset="0.892" stop-color="#239cc6" />
      </linearGradient>
    </defs>
    <path
      id="Path_624"
      data-name="Path 624"
      d="M403.813,315.6l-26.75,21.177a5.07,5.07,0,0,0-1.757,5.286l5.7,21.288,3.13,11.675a4.886,4.886,0,0,1-1.677,5.461c-1.757,1.262-4.487,2.141-7.49-1.118-14.022-15.14-23.413-38.169-23.413-38.169a19.891,19.891,0,0,0,7.076-4.121,19.512,19.512,0,1,0-19.149,4.121s-9.391,23.029-23.413,38.169c-3,3.259-5.733,2.38-7.49,1.118a4.888,4.888,0,0,1-1.677-5.461l8.832-32.963a5.072,5.072,0,0,0-1.757-5.286L287.231,315.6a5.1,5.1,0,0,1,2.858-9.071l34.065-1.789a4.976,4.976,0,0,0,4.5-3.322l11.9-31.972a5.077,5.077,0,0,1,4.711-3.322h.51a5.077,5.077,0,0,1,4.711,3.322l11.9,31.972a4.976,4.976,0,0,0,4.5,3.322l34.065,1.789a5.1,5.1,0,0,1,2.859,9.071Z"
      transform="translate(-285.29 -266.124)"
      fill="url(#linear-gradient)"
    />
    <path
      id="Path_625"
      data-name="Path 625"
      d="M389.131,372.554l3.129,11.674a4.887,4.887,0,0,1-1.676,5.462c-1.757,1.262-4.488,2.141-7.49-1.118-14.022-15.14-23.412-38.169-23.412-38.169S372.608,368.682,389.131,372.554Z"
      transform="translate(-293.414 -275.328)"
      fill="url(#linear-gradient-2)"
    />
    <path
      id="Path_626"
      data-name="Path 626"
      d="M312.66,372.554l-3.13,11.674a4.887,4.887,0,0,0,1.677,5.462c1.757,1.262,4.488,2.141,7.49-1.118,14.022-15.14,23.413-38.169,23.413-38.169S329.181,368.682,312.66,372.554Z"
      transform="translate(-287.912 -275.328)"
      fill="url(#linear-gradient-2)"
    />
    <path
      id="Path_627"
      data-name="Path 627"
      d="M365.538,327.728a14.533,14.533,0,1,0-17.273,14.262L343.9,377.238a1.262,1.262,0,0,0,.249.935l6.209,8.267a.8.8,0,0,0,1.282,0l6.218-8.267a1.263,1.263,0,0,0,.25-.935l-4.445-35.231A14.537,14.537,0,0,0,365.538,327.728Zm-14.538,10a10,10,0,1,1,10-10A10.01,10.01,0,0,1,351,337.731Z"
      transform="translate(-290.879 -271.265)"
      fill="#31418f"
    />
    <path
      id="Path_628"
      data-name="Path 628"
      d="M352.114,334.315a5.474,5.474,0,1,1,5.476-5.474A5.481,5.481,0,0,1,352.114,334.315Z"
      transform="translate(-291.99 -272.375)"
      fill="#259dc6"
    />
  </svg>
</template>

<script>
export default {};
</script>
