<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="87"
    height="87"
    viewBox="0 0 87 87"
  >
    <g id="Group_3674" data-name="Group 3674" transform="translate(-1118 -627)">
      <g id="Group_3673" data-name="Group 3673">
        <g id="Group_3656" data-name="Group 3656">
          <circle
            id="Ellipse_32"
            data-name="Ellipse 32"
            cx="43.5"
            cy="43.5"
            r="43.5"
            transform="translate(1118 627)"
            fill="rgba(37,157,198,0.1)"
          />
          <g
            id="Group_3529"
            data-name="Group 3529"
            transform="translate(1120 628)"
          >
            <rect
              id="Rectangle_1579"
              data-name="Rectangle 1579"
              width="39.078"
              height="2.688"
              transform="translate(10.86 54.765)"
              fill="#259dc6"
            />
            <rect
              id="Rectangle_1580"
              data-name="Rectangle 1580"
              width="34.132"
              height="2.688"
              transform="translate(10.86 66.55)"
              fill="#259dc6"
            />
            <g
              id="Group_3528"
              data-name="Group 3528"
              transform="translate(0 0)"
            >
              <rect
                id="Rectangle_1581"
                data-name="Rectangle 1581"
                width="12.766"
                height="2.688"
                transform="translate(32.802 19.411)"
                fill="#31418f"
              />
              <rect
                id="Rectangle_1582"
                data-name="Rectangle 1582"
                width="7.658"
                height="2.688"
                transform="translate(32.802 31.196)"
                fill="#31418f"
              />
              <rect
                id="Rectangle_1583"
                data-name="Rectangle 1583"
                width="30.536"
                height="2.688"
                transform="translate(10.86 42.98)"
                fill="#259dc6"
              />
              <path
                id="Path_10457"
                data-name="Path 10457"
                d="M29.716,13.975H9.11V33.743H29.716Zm-2.688,17.08H11.8V16.663H27.029Z"
                transform="translate(-0.312 2.788)"
                fill="#31418f"
              />
              <path
                id="Path_10458"
                data-name="Path 10458"
                d="M52.331,19.419V16.731a14.424,14.424,0,0,0-14.4,14.411h2.688A11.734,11.734,0,0,1,52.331,19.419Z"
                transform="translate(9.593 3.736)"
                fill="#31418f"
              />
              <path
                id="Path_10459"
                data-name="Path 10459"
                d="M52.164,37.748l5.018,3.645A1.772,1.772,0,0,0,59.667,41a1.753,1.753,0,0,0,.251-1.59L58,33.511l5.023-3.649a1.776,1.776,0,0,0-1.047-3.21h-6.2l-1.917-5.9a1.775,1.775,0,0,0-3.376,0l-1.917,5.9h-6.2a1.776,1.776,0,0,0-1.045,3.212l5.021,3.647-1.916,5.9A1.751,1.751,0,0,0,44.662,41a1.823,1.823,0,0,0,2.483.4Zm-7-8.409h4.06a1.772,1.772,0,0,0,1.689-1.226l1.255-3.86,1.253,3.859a1.771,1.771,0,0,0,1.689,1.227h4.06l-3.285,2.386a1.772,1.772,0,0,0-.646,1.985l1.255,3.86-3.283-2.386a1.779,1.779,0,0,0-2.088,0l-3.283,2.385,1.255-3.859a1.768,1.768,0,0,0-.646-1.985Z"
                transform="translate(10.505 4.696)"
                fill="#f7c26f"
              />
              <path
                id="Path_10460"
                data-name="Path 10460"
                d="M65.234,15.365a1.343,1.343,0,0,0,1.344-1.344V2.844A1.343,1.343,0,0,0,65.234,1.5H3.907A1.343,1.343,0,0,0,2.563,2.844V73.018a1.338,1.338,0,0,0,.4.955L15.881,87.091a1.379,1.379,0,0,0,.966.409c.01,0,.02-.006.031-.006H65.234c.006,0,.01,0,.016,0,.061,0,.12.009.181.009a7.306,7.306,0,0,0,7.3-7.3V60.374H70.405V56.911a20.482,20.482,0,1,0-9.95.095v3.369H58.133V80.2a7.261,7.261,0,0,0,1.642,4.6H18.181V73.018a1.343,1.343,0,0,0-1.344-1.344H5.25V4.188H63.89v9.834A1.343,1.343,0,0,0,65.234,15.365Zm.2,69.447a4.6,4.6,0,0,1-4.387-3.266h8.774A4.6,4.6,0,0,1,65.431,84.813Zm4.61-5.954h-9.22v-15.8h9.22ZM47.447,37.123a17.786,17.786,0,1,1,17.5,17.759,17.4,17.4,0,0,1-2.908-.272h0A17.734,17.734,0,0,1,47.447,37.123Zm15.7,20.36c.034,0,.068,0,.1,0,.654.064,1.316.1,1.982.1.038,0,.075-.005.113-.006.235,0,.465-.026.7-.035.4-.015,1.242-.056,1.679-.108v2.933H63.143ZM15.494,82.867l-8.375-8.5h8.375Z"
                transform="translate(-2.563 -1.5)"
                fill="#259dc6"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>
