<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.977"
    height="9.488"
    viewBox="0 0 16.977 9.488"
  >
    <g id="arrow-down-circle" transform="translate(1.414 1.414)">
      <path
        id="Path_641"
        data-name="Path 641"
        d="M23.148,13.5l-7.074,7.074L9,13.5"
        transform="translate(-9 -13.5)"
        fill="none"
        stroke="rgba(17,20,22,0.4)"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>
