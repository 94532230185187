<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="180.238"
    height="180.238"
    viewBox="0 0 180.238 180.238"
  >
    <g id="Group_3514" data-name="Group 3514" transform="translate(-2 -2)">
      <path
        id="Path_10437"
        data-name="Path 10437"
        d="M58,10v6.008a6.008,6.008,0,0,1,6.008,6.008v90.119H58v6.008h6.008v6.008A6.008,6.008,0,0,1,58,130.159v6.008a12.016,12.016,0,0,0,12.016-12.016V22.016A12.016,12.016,0,0,0,58,10Z"
        transform="translate(112.222 16.032)"
        fill="rgba(37,157,198,0.2)"
      />
      <path
        id="Path_10438"
        data-name="Path 10438"
        d="M14.016,136.166H73.062L65.852,160.2H59.075v6.008h66.087V160.2H72.125l7.21-24.032h3.773v-6.008H14.016a6.008,6.008,0,0,1-6.008-6.008v-6.008h96.127v-6.008H8.008V22.016a6.008,6.008,0,0,1,6.008-6.008V10A12.016,12.016,0,0,0,2,22.016V124.151a12.016,12.016,0,0,0,12.016,12.016Z"
        transform="translate(0 16.032)"
        fill="rgba(37,157,198,0.2)"
      />
      <path
        id="Path_10439"
        data-name="Path 10439"
        d="M14.008,14.016a6.008,6.008,0,0,1,6.008-6.008H140.174a6.008,6.008,0,0,1,6.008,6.008V122.159h6.008V14.016A12.016,12.016,0,0,0,140.174,2H20.016A12.016,12.016,0,0,0,8,14.016V122.159h6.008Z"
        transform="translate(12.024)"
        fill="rgba(37,157,198,0.2)"
      />
      <rect
        id="Rectangle_1570"
        data-name="Rectangle 1570"
        width="6"
        height="6"
        transform="translate(32 14)"
        fill="rgba(37,157,198,0.2)"
      />
      <rect
        id="Rectangle_1571"
        data-name="Rectangle 1571"
        width="6"
        height="6"
        transform="translate(44 14)"
        fill="rgba(37,157,198,0.2)"
      />
      <rect
        id="Rectangle_1572"
        data-name="Rectangle 1572"
        width="6"
        height="6"
        transform="translate(56 14)"
        fill="rgba(37,157,198,0.2)"
      />
      <path
        id="Path_10440"
        data-name="Path 10440"
        d="M30.02,60.04H78.083V54.032H30.02a9.012,9.012,0,1,1,0-18.024h72.1a9.012,9.012,0,0,1,6.392,15.371,9.4,9.4,0,0,1-1.886,1.442l3,5.2a15.44,15.44,0,0,0,3.13-2.4A15.02,15.02,0,0,0,102.115,30H30.02a15.02,15.02,0,1,0,0,30.04Z"
        transform="translate(26.052 56.111)"
        fill="rgba(37,157,198,0.2)"
      />
      <rect
        id="Rectangle_1573"
        data-name="Rectangle 1573"
        width="6"
        height="6"
        transform="translate(89 98)"
        fill="rgba(37,157,198,0.2)"
      />
      <rect
        id="Rectangle_1574"
        data-name="Rectangle 1574"
        width="6"
        height="6"
        transform="translate(101 98)"
        fill="rgba(37,157,198,0.2)"
      />
      <rect
        id="Rectangle_1575"
        data-name="Rectangle 1575"
        width="6"
        height="6"
        transform="translate(77 98)"
        fill="rgba(37,157,198,0.2)"
      />
      <rect
        id="Rectangle_1576"
        data-name="Rectangle 1576"
        width="6"
        height="6"
        transform="translate(65 98)"
        fill="rgba(37,157,198,0.2)"
      />
      <rect
        id="Rectangle_1577"
        data-name="Rectangle 1577"
        width="6"
        height="6"
        transform="translate(53 98)"
        fill="rgba(37,157,198,0.2)"
      />
      <path
        id="Path_10441"
        data-name="Path 10441"
        d="M31.245,56.482c.216.207.451.388.67.6.375.339.748.685,1.142,1,.321.258.655.493.982.736s.718.538,1.093.787.8.493,1.2.733c.333.2.661.4,1.006.6.454.24.922.454,1.388.67.318.147.631.3.955.442.493.2,1,.375,1.5.553.318.111.631.234.955.333.52.159,1.054.282,1.586.409.324.078.643.174.973.24.58.114,1.166.189,1.754.267.3.039.58.1.877.129.9.087,1.8.135,2.7.135s1.8-.048,2.7-.135c.3-.03.583-.09.877-.129.6-.078,1.175-.153,1.754-.267.33-.066.649-.162.973-.24.532-.126,1.066-.249,1.586-.409.324-.1.637-.222.955-.333.508-.177,1.015-.348,1.5-.553.324-.135.637-.3.955-.442.466-.216.934-.43,1.388-.67.345-.186.673-.394,1.006-.6.406-.24.811-.472,1.2-.733s.73-.523,1.093-.787.661-.478.982-.736c.394-.318.766-.664,1.142-1,.219-.2.454-.382.67-.6.021-.021.03-.045.051-.066a27.036,27.036,0,1,0-37.676,0A.43.43,0,0,0,31.245,56.482ZM44.024,34.057a6.008,6.008,0,1,1,6.008,6.008A6.008,6.008,0,0,1,44.024,34.057Zm6.008,12.016a14.942,14.942,0,0,1,12.989,7.492l-.174.129a20.73,20.73,0,0,1-2.7,1.8l-.054.033A21.1,21.1,0,0,1,57.233,56.8c-.177.063-.351.132-.529.192a20.648,20.648,0,0,1-2.923.751c-.192.036-.391.06-.6.09a19.093,19.093,0,0,1-6.332,0c-.2-.03-.394-.054-.6-.09a20.648,20.648,0,0,1-2.923-.751c-.177-.06-.351-.129-.529-.192a21.1,21.1,0,0,1-2.857-1.271l-.054-.033a20.727,20.727,0,0,1-2.7-1.8l-.174-.129A14.942,14.942,0,0,1,50.032,46.073Zm0-30.04a21,21,0,0,1,17.21,33.074,20.92,20.92,0,0,0-8.3-7.062,12.016,12.016,0,1,0-17.82,0,20.92,20.92,0,0,0-8.3,7.062,21,21,0,0,1,17.21-33.074Z"
        transform="translate(42.087 16.006)"
        fill="rgba(37,157,198,0.2)"
      />
      <path
        id="Path_10442"
        data-name="Path 10442"
        d="M36.067,77.891l-.475.237a8.309,8.309,0,0,0-1.114,14.191l18.808,13.434a3,3,0,0,0,1.745.559h48.063a3,3,0,0,0,3-3V70.585a9.258,9.258,0,0,0-7.51-9.21,9.039,9.039,0,0,0-7.33,2.022l-.18.153a8.994,8.994,0,0,0-12.016,0,9.366,9.366,0,0,0-9.012-1.787V43.228a9.255,9.255,0,0,0-7.51-9.21,9.012,9.012,0,0,0-10.514,8.889V82.983L44.054,78.2A8.261,8.261,0,0,0,36.067,77.891ZM53.49,90.862a3,3,0,0,0,4.545-2.574V42.907a3,3,0,0,1,3.533-2.959,3.25,3.25,0,0,1,2.475,3.28V70.264a3,3,0,0,0,6.008,0,3,3,0,1,1,6.008,0,3,3,0,0,0,6.008,0,3,3,0,1,1,6.008,0,3,3,0,0,0,6.008,0,3.012,3.012,0,0,1,3.53-2.962,3.259,3.259,0,0,1,2.478,3.283V100.3h-44.1L37.968,87.432a2.31,2.31,0,0,1-.961-1.874A2.283,2.283,0,0,1,38.275,83.5l.475-.237a2.28,2.28,0,0,1,2.211.084Z"
        transform="translate(58.116 63.91)"
        fill="rgba(37,157,198,0.2)"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>
